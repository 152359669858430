import { useEffect, useRef } from "react";

export default function Modal({ title, body, onClose, hideHeader, widthClass, absolute, hideClose, noPadding }) {
  let _width = widthClass || "w-2/3";
  let _padding = noPadding ? "rounded-lg overflow-hidden" : "p-4";
  let _absolute = absolute || "relative";
  let _hideHeader = typeof hideHeader !== "undefined" ? hideHeader : false;
  let _hideClose = typeof hideClose !== "undefined" ? hideClose : false;

  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none bg-[#0808082f]">
        <div className={`${_absolute} ${_width} my-6  mx-auto max-w-3xl max-h-screen`}>
          {/*content*/}
          <div
            ref={modalRef}
            className={`border-0 rounded-lg shadow-lg ${
              !_hideHeader ? "flex flex-col" : "relative"
            } w-full bg-white outline-none`}
          >
            {/*header*/}
            {!_hideHeader && (
              <div>
                <div
                  className={`flex items-start justify-between border-b border-solid border-slate-200 rounded-t p-5`}
                >
                  <h3 className="text-3xl font-semibold">{title}</h3>
                  <button
                    className="p-1 ml-auto border-0 float-right text-3xl leading-none font-semibold"
                    onClick={() => onClose()}
                  >
                    <span className="text-black">
                      <i className="fas fa-times-circle"></i>
                    </span>
                  </button>
                </div>
              </div>
            )}
            {_hideHeader && (
              <div>
                {!_hideClose ? (
                  <button
                    className="absolute text-3xl font-semibold z-50 leading-none top-2 right-2"
                    onClick={() => onClose()}
                  >
                    <span className="text-black">
                      <i className="fas fa-times-circle"></i>
                    </span>
                  </button>
                ) : (
                  <div></div>
                )}
              </div>
            )}
            {/*body*/}
            <div className={`relative  ${_padding}`}>{body}</div>
          </div>
        </div>

        {_hideClose && (
          <div
            className="absolute rounded-full bottom-4 right-4 font-bold py-2 px-4 bg-white cursor-pointer"
            onClick={() => onClose()}
          >
            ?
          </div>
        )}
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
}
