import axios from "axios";
import Cookies from "js-cookie";

const api = axios.create({
  baseURL: typeof process !== "undefined" ? `${process.env.REACT_APP_API_BASE_URL}` : "/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = Cookies.get("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    if (error.response) {
      const { status } = error.response;
      if (status === 401 && originalRequest.url !== "/clients/tokens") {
        Cookies.remove("token");
        delete api.defaults.headers.Authorization;
        window.location.reload();
      }
    }

    return Promise.reject(error);
  }
);

async function get(url, params, options) {
  try {
    params = params || {};
    options = options || {};
    const res = await api.get(url, {
      params: params,
      ...options,
    });

    return {
      err: null,
      data: res.data,
      res: res,
    };
  } catch (error) {
    const res = error.response;
    let message = error.message;

    return {
      err: {
        message: message,
      },
      data: res ? res.data : null,
      res: res,
    };
  }
}

const req = async (method, url, data, options) => {
  try {
    const res = await api[method](url, data, options);
    return {
      err: null,
      data: res.data,
      res: res,
    };
  } catch (error) {
    const res = error.response;
    const data = res.data;
    const errors = data.errors ?? null;

    let errMessage = null;
    if (res.status >= 400 && res.status < 500) {
      if (errors) {
        for (const prop in errors) {
          if (errors.hasOwnProperty(prop)) {
            for (let i = 0; i < prop.length; i++) {
              errMessage = errors[prop];
              break;
            }
          }
          if (errMessage) break;
        }
      } else {
        for (const prop in data) {
          if (data.hasOwnProperty(prop)) {
            for (let i = 0; i < prop.length; i++) {
              errMessage = data[prop][0];
              break;
            }
          }
          if (errMessage) break;
        }
      }
    }
    errMessage = errMessage ? errMessage : error.message;

    return {
      err: {
        message: errMessage,
      },
      data: res ? res.data : null,
      res: res,
    };
  }
};
async function post(url, data, options) {
  return await req("post", url, data, options);
}
async function put(url, data, options) {
  return await req("put", url, data, options);
}
async function patch(url, data, options) {
  return await req("patch", url, data, options);
}
async function del(url, options) {
  return await req("delete", url, {}, options);
}

api.controllers = {
  // AUTH
  createToken: (email, password) => {
    return post("/clients/tokens", {
      email: email,
      password: password,
    });
  },
  register: (params) => {
    return post("/clients", params);
  },
  verify: (token) => {
    return post("/clients/users/verifications", { token: token });
  },
  requestResetPassword: (email) => {
    return post("/clients/reset-password/requests", { email: email });
  },
  verifyResetPassword: (token) => {
    return post("/clients/reset-password/verifications", {
      token: token,
    });
  },
  resetPassword: (token, password) => {
    return post("/clients/reset-password/password", {
      token: token,
      password: password,
    });
  },
  me: () => {
    return get("/clients/admin/users/me");
  },

  // GROUPS
  getGroup: (id) => {
    return get(`/recipients/admin/groups/${id}`);
  },
  getGroups: (page, limit) => {
    return get(`/recipients/admin/groups?page=${page}&limit=${limit}`);
  },
  createGroup: (params) => {
    return post("/recipients/admin/groups", params);
  },
  updateGroup: (id, params) => {
    return put(`/recipients/admin/groups/${id}`, params);
  },
  getGroupRecipients: (id, page, limit, filters) => {
    let params = {
      page: page || 1,
      limit: limit || 10,
    };
    let _filters = filters || {};
    params = { ...params, ..._filters };

    return get(`/recipients/admin/groups/${id}/recipients`, params);
  },
  addGroupRecipients: (id, params) => {
    return post(`/recipients/admin/groups/${id}/recipients`, params);
  },
  deleteGroupRecipient: (id, recipientId) => {
    return del(`/recipients/admin/groups/${id}/recipients/${recipientId}`);
  },

  // RECIPIENTS
  getRecipient: (id) => {
    return get(`/recipients/admin/recipients/${id}`);
  },
  getRecipients: (page, limit, filters) => {
    let params = {
      page: page || 1,
      limit: limit || 10,
    };
    let _filters = filters || {};
    params = { ...params, ..._filters };

    return get(`/recipients/admin/recipients`, params);
  },
  filterRecipients: (params) => {
    return post("/recipients/admin/recipients/list?limit=-1", params);
  },
  createRecipient: (params) => {
    return post("/recipients/admin/recipients", params);
  },
  updateRecipient: (id, params) => {
    const values = {
      name: params.name,
      birthdate: params.birthdate,
      onboarding_date: params.onboarding_date,
      active: params.active,
      ...(params.phone_number.length > 2 && { phone_number: params.phone_number }),
      ...(params.email.length > 2 && { email: params.email }),
      ...(params.default_address && { default_address: params.default_address }),
    };
    return put(`/recipients/admin/recipients/${id}`, values);
  },
  importRecipient: (params) => {
    let formData = new FormData();
    formData.append("file", params.file);

    return post(`/recipients/admin/recipients/import`, formData);
  },

  downloadRecipient: () => {
    return get(`/recipients/admin/recipients/download`);
  },

  // USERS
  getUser: (id) => {
    return get(`/clients/admin/users/${id}`);
  },
  getUsers: (page, limit) => {
    return get(`/clients/admin/users?page=${page}&limit=${limit}`);
  },
  createUser: (params) => {
    return post("/clients/admin/users", params);
  },
  updateUser: (id, params) => {
    return put(`/clients/admin/users/${id}`, params);
  },

  // ORDERS
  getOrder: (id) => {
    return get(`/orders/admin/orders/${id}`);
  },
  getOrderSummary: () => {
    return get(`/orders/admin/orders/summary`);
  },
  getOrderReviews: (page, limit, params) => {
    let _params = {
      ...{
        page: page,
        limit: limit,
      },
      ...params,
    };
    return get(`/orders/admin/orders/reviews`, _params);
  },
  getOrders: (page, limit, params) => {
    let _params = {
      ...{
        page: page,
        limit: limit,
      },
      ...params,
    };
    return get(`/orders/admin/orders`, _params);
  },
  getOrderSchedule: (id, params) => {
    // start_date
    // end_date
    return get(`/orders/admin/orders/${id}/recipients/schedules`, params);
  },
  getOrderProducts: (id) => {
    return get(`/orders/admin/orders/${id}/products`);
  },
  getOrderRecipients: (id, params) => {
    return get(`/orders/admin/orders/${id}/recipients`, params);
  },

  addOrderRecipients: (id, params) => {
    return post(`/orders/admin/orders/${id}/recipients`, {
      recipients: params,
    });
  },
  getOrderConditions: (id) => {
    return get(`/orders/admin/orders/${id}/conditions`);
  },
  addOrderConditions: (id, params) => {
    return post(`/orders/admin/orders/${id}/conditions`, params);
  },
  createOrder: (params) => {
    return post("/orders/admin/orders", params);
  },
  patchOrder: (id, params) => {
    return patch(`/orders/admin/orders/${id}`, params);
  },
  addOrderProduct: (id, params) => {
    return post(`/orders/admin/orders/${id}/products`, params);
  },
  downloadInvoice: (id) => {
    return get(
      `/orders/admin/orders/${id}/invoice`,
      {},
      {
        responseType: "blob",
      }
    );
  },
  paymentOrder: (id) => {
    return post(`/orders/admin/orders/${id}/payment-links`);
  },
  updateDesign: (id, productId, params) => {
    let formData = new FormData();
    formData.append("design", params.design);

    return post(`/orders/admin/orders/${id}/products/${productId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateOrderNotification: (id, params) => {
    let formData = new FormData();
    if (params.notification_lang) {
      formData.append("notification_lang", params.notification_lang);
    }
    if (params.notification_image) {
      formData.append("notification_image", params.notification_image);
    }
    if (params.notification_title) {
      formData.append("notification_title", params.notification_title);
    }
    if (params.notification_body) {
      formData.append("notification_body", params.notification_body);
    }
    if (params.notification_action) {
      formData.append("notification_action", params.notification_action);
    }

    return post(`/orders/admin/orders/${id}/notification`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  publishOrder: (id) => {
    return post(`/orders/admin/orders/${id}/publish`);
  },
  deleteProduct: (id, idProduct) => {
    return del(`/orders/admin/orders/${id}/products/${idProduct}`);
  },

  // PRODUCTS
  getCategories: () => {
    return get(`/products/admin/categories`);
  },
  getProductCategories: (_params) => {
    let params = _params || {};

    return get(`/products/admin/products/categories`, params);
  },
  getProductSubcategories: (id, params) => {
    return get(`/products/admin/products/categories/${id}/subcategories`, params);
  },
  getProduct: (id) => {
    return get(`/products/admin/products/${id}`);
  },

  // params => page, limit, name, category_id, category_type, tag_id, price, personal, sort
  getProducts: (params) => {
    let _params = {
      ...{
        page: 1,
        limit: 20,
      },
      ...params,
    };

    return get(`/products/admin/products`, _params);
  },

  // INTEGRATIONS
  getIntegrations: () => {
    return get(`/clients/admin/integrations`);
  },
  getIntegration: (integration) => {
    return get(`/clients/admin/integrations/${integration}`);
  },
  integrationAuthSlack: (code, redirectUri) => {
    return post(`/clients/admin/integrations/slack/auth`, {
      code: code,
      redirect_uri: redirectUri,
    });
  },
  integrationAuthTalenta: (params) => {
    return post(`/clients/admin/integrations/talenta/auth`, params);
  },
  integrationAuthDarwinbox: (params) => {
    return post(`/clients/admin/integrations/darwinbox/auth`, params);
  },

  // VOUCHER
  addVoucher: (id, params) => {
    return put(`/orders/admin/orders/${id}/voucher`, params);
  },
  deleteVoucher: (id) => {
    return del(`/orders/admin/orders/${id}/voucher`);
  },

  // REGIONS
  getRegions: (_params) => {
    let params = _params || {};

    return get(`/recipients/regions`, params);
  },

  // TRACK
  getRecipientsStatus: (id, recipientId) => {
    return get(`/orders/admin/orders/${id}/recipients/${recipientId}/delivery-histories`);
  },

  // FREE GIFT
  getFreeGift: () => {
    return post(`/orders/admin/orders/free`);
  },
};

export default api;
