import api from "lib/api";
import React, { useEffect, useState } from "react";

export default function Sample({ order, hideTitle, type }) {
  let showTitle = hideTitle ? hideTitle : false;
  let production = type === "production" ? true : false;
  let samples = type === "sample" ? true : false;
  const [products, setProducts] = useState([]);
  const [itemIndex, setItemIndex] = useState(0);
  const [sample, setSample] = useState({
    status: null,
    photo: null,
  });

  const getOrderProducts = async (id) => {
    const { data, err } = await api.controllers.getOrderProducts(id);
    if (!err) {
      setProducts(data.data);
    }
  };

  useEffect(() => {
    order && getOrderProducts(order.id);
  }, [order]);

  useEffect(() => {
    let firstItem = products.filter((product) =>
      production ? product.production_status !== null : product.sample_status !== null
    );
    if (samples) {
      setSample((sample) => ({
        ...sample,
        status: firstItem[0]?.sample_status,
        photo: firstItem[0]?.sample_photo_url,
      }));
    } else {
      setSample((sample) => ({
        ...sample,
        status: firstItem[0]?.production_status,
        photo: firstItem[0]?.production_photo_url,
      }));
    }
  }, [products, production, samples]);

  let renderRecipients = (_products) => {
    return _products
      ?.filter((product) => (production ? product.production_status !== null : product.sample_status !== null))
      .map((product, i) => {
        return (
          <div
            onClick={() => {
              setItemIndex(i);
              if (samples) {
                setSample({ ...sample, status: product.sample_status, photo: product.sample_photo_url });
              } else {
                setSample({ ...sample, status: product.production_status, photo: product.production_photo_url });
              }
            }}
            key={product.id}
            className={`flex justify-between items-center px-3 py-3 cursor-pointer  ${
              itemIndex === i ? "border-2 rounded-md border-tertiary_light_blue" : "border-b border-gray-300"
            }`}
          >
            <div className={`w-8/12 py-3 mr-3 px-3 flex items-center`}>
              <h4 className="mb-1"> {product.product_name}</h4>
            </div>

            {samples && product.sample_status === "completed" && (
              <img
                src={product.sample_photo_url}
                alt={product.product_name}
                className="w-16 h-16 rounded-lg mr-2 object-contain"
              />
            )}

            {production && product.production_status === "completed" && (
              <img
                src={product.production_photo_url}
                alt={product.product_name}
                className="w-16 h-16 rounded-lg mr-2 object-contain"
              />
            )}

            <div className={`flex items-center`}>
              {production && (
                <p
                  className={`text-sm px-3 py-1 rounded-md font-bold ${
                    product.production_status === "completed"
                      ? "bg-green-200 text-tertiary_green_one"
                      : "bg-secondaryTwo text-primary"
                  }`}
                >
                  {product.production_status === "completed" ? "Completed" : "On Progress"}
                </p>
              )}

              {samples && (
                <p
                  className={`text-sm px-3 py-1 rounded-md font-bold ${
                    product.sample_status === "completed"
                      ? "bg-green-200 text-tertiary_green_one"
                      : "bg-secondaryTwo text-primary"
                  }`}
                >
                  {product.sample_status === "completed" ? "Completed" : "On Progress"}
                </p>
              )}
            </div>
          </div>
        );
      });
  };

  return (
    <div className="flex flex-wrap mt-4">
      <div className="w-full mb-12 px-4">
        {!showTitle && (
          <div className="pb-10">
            {" "}
            <h3 className="text-2xl font-bold text-center">Gift Progress</h3>
          </div>
        )}

        {showTitle && order.production_estimated_date && (
          <div className="relative w-full text-sm flex items-center justify-center bg-blue-100 rounded-md border border-blue-500 p-3 mb-5">
            <div className="flex items-center text-blue-500 select-none">
              <img src={require("assets/img/order-edit/info.svg").default} alt={"info"} className="w-6 mr-2" />
              production is estimated to be completed on{" "}
              <span className="font-bold underline ml-1">{order.production_estimated_date}</span>
            </div>
          </div>
        )}
        <div className="w-full pr-5 mb-2 flex gap-5">
          <div className={`w-6/12 bg-transparent border-collapse ${production ? "" : "h-[430px] overflow-auto"}  `}>
            {renderRecipients(products)}
          </div>

          <div
            className={`w-6/12 ${
              sample.status === "completed" ? "" : "bg-tertiary_gray_one h-[430px]"
            }  flex items-center `}
          >
            {sample.status === "completed" ? (
              <img src={sample.photo} alt="sample-gambar" className="w-full rounded-md shadow" />
            ) : (
              <p className="px-14 text-center text-sm">
                Sample for this product is in production. Once completed, photo of final product will be uploaded here.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
