import api from "lib/api";
import { useEffect, useState } from "react";

export default function OrderConditions({ order }) {
  const [conditions, setConditions] = useState([]);
  const [groups, setGroups] = useState([]);

  let getGroups = async () => {
    const { data, err } = await api.controllers.getGroups(1, -1);
    if (!err) {
      let _groups = {};
      data.data.forEach((group) => {
        _groups[group.id] = group.name;
      });
      setGroups(_groups);
    }
  };

  let getOrderConditions = async (id) => {
    const { data, err } = await api.controllers.getOrderConditions(id);
    if (!err) {
      setConditions(data.data);
    }
  };

  useEffect(() => {
    getGroups();
    getOrderConditions(order.id);
  }, [order.id]);

  return (
    <div className={`w-full flex gap-x-4 mb-2`}>
      {conditions.map((condition) => {
        return (
          <div key={condition.id} className="mb-2 bg-gray-100 p-2 rounded-lg">
            <span className="capitalize mr-2">
              {condition.field === "tenure" && "Work Tenure"}
              {condition.field !== "tenure" && condition.field}
            </span>
            {(condition.field === "onboarding" ||
              condition.field === "birthday" ||
              condition.field === "anniversary") && (
              <p>
                <span className="mr-2">In</span>
                <span className="mr-2">
                  {condition.value === "1_week" && "1 week"}
                  {condition.value === "2_weeks" && "2 weeks"}
                  {condition.value === "1_month" && "1 month"}
                </span>
              </p>
            )}
            {condition.field === "tenure" && (
              <p>
                <span className="mr-2">{condition.operator}</span>
                <span className="mr-2">{condition.value} months</span>
              </p>
            )}
            {condition.field === "group" && (
              <p>
                <span className="mr-2">=</span>
                <span className="mr-2">{groups[condition.value]}</span>
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
}
