import React from "react";

const OrderHeaderSteps = ({ order, step }) => {
  let isActivate = order.activate;
  let isRecurring = order.recurring;
  let isToCompany = order.deliver_to === "company";
  let isToRecipients = order.deliver_to === "recipients";

  let steps = order.steps;
  let activeClassName = "w-full h-3 border-b-2 border-dashed border-green-500 ";
  let inactiveClassName = "w-full h-3 border-b-2 border-dashed border-gray-400 ";

  let stepsOrder = {
    item: 1,
    packaging: 2,
    decoration: 3,
    quotation: 4,
    recipient: 5,
    delivery: 6,
    message: 7,
    preview: 8,
    published: 9,
  };

  return (
    <div className="mt-5">
      <div className="mb-4 border-b border-gray-300 pb-10">
        <div className="mx-auto flex justify-between gap-x-8 w-10/12">
          <div className="flex flex-col items-center">
            <img
              src={require("assets/img/order-edit/green-check-header.png").default}
              alt={"green-check-header"}
              className="w-6 block"
            />
            <p className="font-bold text-green-500">Gift</p>
          </div>

          {/* Proposal */}
          <div>
            <div
              className={
                steps.indexOf("payment") !== -1 ||
                steps.indexOf("recipient") !== -1 ||
                stepsOrder[step] >= stepsOrder["quotation"]
                  ? activeClassName
                  : inactiveClassName
              }
            ></div>
            <div className="flex flex-col items-center">
              {steps.indexOf("payment") !== -1 ||
              steps.indexOf("recipient") !== -1 ||
              stepsOrder[step] >= stepsOrder["quotation"] ? (
                <img
                  src={require("assets/img/order-edit/green-check-header.png").default}
                  alt={"green-check-header"}
                  className="w-6 block"
                />
              ) : (
                <img
                  src={require("assets/img/order-edit/gray-check-header.png").default}
                  alt={"gray-check-header"}
                  className="w-6 block"
                />
              )}
              <p
                className={
                  steps.indexOf("payment") !== -1 ||
                  steps.indexOf("recipient") !== -1 ||
                  stepsOrder[step] >= stepsOrder["quotation"]
                    ? "font-bold text-green-500"
                    : "font-bold text-gray-400"
                }
              >
                Proposal
              </p>
            </div>
          </div>

          {/* Recipient */}
          {!isActivate && isRecurring && (
            <div>
              <div
                className={
                  steps.indexOf("payment") !== -1 ||
                  steps.indexOf("recipient") !== -1 ||
                  stepsOrder[step] >= stepsOrder["recipient"]
                    ? activeClassName
                    : inactiveClassName
                }
              ></div>
              <div className="flex flex-col items-center">
                {stepsOrder[step] >= stepsOrder["recipient"] ? (
                  <img
                    src={require("assets/img/order-edit/green-check-header.png").default}
                    alt={"green-check-header"}
                    className="w-6 block"
                  />
                ) : (
                  <img
                    src={require("assets/img/order-edit/gray-check-header.png").default}
                    alt={"gray-check-header"}
                    className="w-6 block"
                  />
                )}
                <p
                  className={
                    stepsOrder[step] >= stepsOrder["recipient"] ? "font-bold text-green-500" : "font-bold text-gray-400"
                  }
                >
                  Recipient
                </p>
              </div>
            </div>
          )}

          {!isActivate && !isRecurring && isToRecipients && (
            <div>
              <div
                className={
                  steps.indexOf("payment") !== -1 ||
                  steps.indexOf("recipient") !== -1 ||
                  stepsOrder[step] >= stepsOrder["recipient"]
                    ? activeClassName
                    : inactiveClassName
                }
              ></div>
              <div className="flex flex-col items-center">
                {stepsOrder[step] >= stepsOrder["recipient"] ? (
                  <img
                    src={require("assets/img/order-edit/green-check-header.png").default}
                    alt={"green-check-header"}
                    className="w-6 block"
                  />
                ) : (
                  <img
                    src={require("assets/img/order-edit/gray-check-header.png").default}
                    alt={"gray-check-header"}
                    className="w-6 block"
                  />
                )}
                <p
                  className={
                    stepsOrder[step] >= stepsOrder["recipient"] ? "font-bold text-green-500" : "font-bold text-gray-400"
                  }
                >
                  Recipient
                </p>
              </div>
            </div>
          )}

          {/* delivery  */}
          {!isActivate && !isRecurring && isToCompany && (
            <div>
              <div className={stepsOrder[step] >= stepsOrder["delivery"] ? activeClassName : inactiveClassName}></div>
              <div className="flex flex-col items-center">
                {stepsOrder[step] >= stepsOrder["delivery"] ? (
                  <img
                    src={require("assets/img/order-edit/green-check-header.png").default}
                    alt={"green-check-header"}
                    className="w-6 block"
                  />
                ) : (
                  <img
                    src={require("assets/img/order-edit/gray-check-header.png").default}
                    alt={"gray-check-header"}
                    className="w-6 block"
                  />
                )}
                <p
                  className={
                    stepsOrder[step] >= stepsOrder["delivery"] ? "font-bold text-green-500" : "font-bold text-gray-400"
                  }
                >
                  delivery
                </p>
              </div>
            </div>
          )}

          {/* Message */}
          {isToRecipients && (
            <div>
              <div className={stepsOrder[step] >= stepsOrder["message"] ? activeClassName : inactiveClassName}></div>
              <div className="flex flex-col items-center">
                {stepsOrder[step] >= stepsOrder["message"] ? (
                  <img
                    src={require("assets/img/order-edit/green-check-header.png").default}
                    alt={"green-check-header"}
                    className="w-6 block"
                  />
                ) : (
                  <img
                    src={require("assets/img/order-edit/gray-check-header.png").default}
                    alt={"gray-check-header"}
                    className="w-6 block"
                  />
                )}
                <p
                  className={
                    stepsOrder[step] >= stepsOrder["message"] ? "font-bold text-green-500" : "font-bold text-gray-400"
                  }
                >
                  Message
                </p>
              </div>
            </div>
          )}

          {/* Preview */}
          {!isActivate && (
            <div>
              <div className={stepsOrder[step] >= stepsOrder["preview"] ? activeClassName : inactiveClassName}></div>
              <div className="flex flex-col items-center">
                {stepsOrder[step] >= stepsOrder["preview"] ? (
                  <img
                    src={require("assets/img/order-edit/green-check-header.png").default}
                    alt={"green-check-header"}
                    className="w-6 block"
                  />
                ) : (
                  <img
                    src={require("assets/img/order-edit/gray-check-header.png").default}
                    alt={"gray-check-header"}
                    className="w-6 block"
                  />
                )}
                <p
                  className={
                    stepsOrder[step] >= stepsOrder["preview"] ? "font-bold text-green-500" : "font-bold text-gray-400"
                  }
                >
                  Preview
                </p>
              </div>
            </div>
          )}

          {/* Published */}
          <div>
            <div className={stepsOrder[step] >= stepsOrder["published"] ? activeClassName : inactiveClassName}></div>
            <div className="flex flex-col items-center">
              {steps.indexOf("published") !== -1 || step === "published" ? (
                <img
                  src={require("assets/img/order-edit/green-check-header.png").default}
                  alt={"green-check-header"}
                  className="w-6 block"
                />
              ) : (
                <img
                  src={require("assets/img/order-edit/gray-check-header.png").default}
                  alt={"gray-check-header"}
                  className="w-6 block"
                />
              )}
              <p
                className={
                  steps.indexOf("published") !== -1 || step === "published"
                    ? "font-bold text-green-500"
                    : "font-bold text-gray-400"
                }
              >
                Published
              </p>
            </div>

            {/* <div
  className={
    steps.indexOf("published") !== -1 || step === "published"
      ? activeClassName
      : inactiveClassName
  }
  ></div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHeaderSteps;
