import React, { useEffect, useState } from "react";

import api from "lib/api";
import mixpanel from "lib/mixpanel";
import Pagination from "components/Pagination";
import OrderNew from "./OrderNew";
import OrderGrid from "./OrderGrid";

export default function OrderList() {
  const [data, setData] = useState({});
  const [pagination, setPagination] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [autoNamingCampaign, setAutoNamingCampaign] = useState("");
  const [showModalNewOrder, setShowModalNewOrder] = useState(false);
  const campaignsName = [
    {
      name: "Onboarding",
      img: require("assets/img/home/home-onboarding.png").default,
    },
    {
      name: "Birthday",
      img: require("assets/img/home/home-birthday.png").default,
    },
    {
      name: "VIP",
      img: require("assets/img/home/home-vip.png").default,
    },
    {
      name: "Health Kit",
      img: require("assets/img/home/home-health-kit.png").default,
    },
    {
      name: "Merchandise",
      img: require("assets/img/home/home-merchandise.png").default,
    },
    {
      name: "Anniversary",
      img: require("assets/img/home/home-anniversary.png").default,
    },
    {
      name: "Event",
      img: require("assets/img/home/home-event.png").default,
    },
  ];
  const [search, setSearch] = useState({
    name: "",
    status: "",
  });

  const [limit, setLimit] = useState(20);

  // let getOrders = async () => {
  //   if (loadingData) return;

  //   setLoadingData(true);
  //   const { data, err } = await api.controllers.getOrders(pagination.page, pagination.limit, {
  //     name: search.name,
  //     status: search.status,
  //   });
  //   setLoadingData(false);

  //   if (!err) {
  //     setData(data);
  //   }
  // };

  useEffect(() => {
    mixpanel.track("List Campaigns");
  }, []);

  useEffect(() => {
    if (pagination) {
      let getOrders = async () => {
        if (loadingData) return;

        setLoadingData(true);
        const { data, err } = await api.controllers.getOrders(pagination.page, pagination.limit, {
          name: search.name,
          status: search.status,
        });
        setLoadingData(false);

        if (!err) {
          setData(data);
        }
      };
      getOrders();
    }
  }, [pagination]); // eslint-disable-line react-hooks/exhaustive-deps

  // const statusMap = {
  //   draft: "Draft",
  //   processing: "Proccessing",
  //   completed: "Completed",
  //   expired: "Expired",
  // };

  const onPageChange = (page, limitPerPage) => {
    setPagination({ page: page, limit: limitPerPage ? limitPerPage : limit });
    setLimit(limitPerPage);
  };

  let resetPagination = () => {
    setPagination({ page: 1, limit: limit });
  };

  let onUpdateName = () => {
    resetPagination();
  };
  let onQueryEnter = (e) => {
    if (e.key === "Enter") {
      resetPagination();
    }
  };
  let onQueryChange = (e) => {
    setSearch({ ...search, name: e.target.value });
  };
  let handleChange = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  return (
    <div className="mt-4">
      {showModalNewOrder && (
        <OrderNew
          campaignName={autoNamingCampaign}
          onClose={() => {
            setShowModalNewOrder((prev) => !prev);
            setAutoNamingCampaign("");
          }}
        />
      )}

      {/* create new campaign  */}
      <div className="absolute flex justify-between items-center top-10 left-0 w-full pl-14 pr-36 pt-10 h-80 bg_gradient_cyan">
        <div className="text-white">
          <h1 className="text-5xl font-bold">Campaigns</h1>
          <p className="text-3xl mt-2">Create your campaign and delight your recipients</p>
        </div>
        <img
          src={require("assets/img/campaign/campaign-hero.png").default}
          alt={"campaign-hero"}
          className="w-80 block mt-16"
        />
      </div>

      <div className="w-full px-4 mt-72">
        <h2 className="px-4 text-2xl font-bold">Suggestion Campaigns</h2>
        <div className="grid grid-cols-4 gap-3 mt-3">
          <div
            className="flex flex-col items-center justify-center rounded-lg p-2 cursor-pointer campaigns_add_bg"
            onClick={() => setShowModalNewOrder((prev) => !prev)}
          >
            <img
              src={require("assets/img/add-icon.png").default}
              alt={"home-no-campaign-icon"}
              className="w-16 block mb-4"
            />
            <p>Create your own</p>
          </div>

          {campaignsName.map((item) => {
            return (
              <div
                key={`campaign ${item.name}`}
                className="flex flex-col items-center rounded-lg pb-4 cursor-pointer campaigns_option_bg"
                onClick={() => {
                  setShowModalNewOrder((prev) => !prev);
                  setAutoNamingCampaign(item.name);
                }}
              >
                <img src={item.img} alt={"home-guide-icon"} className="w-52 block mb-4" />
                <p className="font-medium">{item.name}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className="w-full mb-12 mt-16 px-4">
        <div className="flex justify-between">
          <h2 className="px-4 text-2xl font-bold mb-3 ">Campaigns</h2>
          <div className="flex gap-x-2 items-center pb-3">
            <div>
              <select name="status" className="h-fit border-none shadow" onChange={handleChange}>
                <option value="">Status</option>

                <option value="draft">Draft</option>
                <option value="processing">Processing</option>
                <option value="completed">Completed</option>
                <option value="expired">Expired</option>
              </select>
            </div>

            <div className="relative h-fit flex justify-center items-center max-w-[250px]">
              <input
                name="name"
                type="search"
                className="border-0 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded-l text-sm shadow w-full"
                placeholder="Search"
                value={search.name}
                onChange={onQueryChange}
                onKeyUp={onQueryEnter}
              />
              <div
                className="bg-primary text-white font-bold uppercase text px-3 py-2 rounded-r shadow cursor-pointer"
                onClick={onUpdateName}
              >
                <i className="fas fa-search" />
              </div>
            </div>
          </div>
        </div>

        <div>{<OrderGrid orders={data.data} />}</div>

        <Pagination
          itemsPerPage={limit}
          pageCount={data.total_page}
          itemCount={data.total_items}
          onPageChange={onPageChange}
          withBackground={false}
          showAll={true}
        />
      </div>
    </div>
  );
}
