import React, { useEffect, useState } from "react";

import api from "lib/api";
import Reviews from "components/Reviews/Reviews";
import Pagination from "components/Pagination";

export default function ReviewsList() {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState(null);

  useEffect(() => {
    if (pagination) {
      let getReviews = async () => {
        const { data, err } = await api.controllers.getOrderReviews(pagination.page, pagination.limit);
        if (!err) {
          setData(data);
        }
      };
      getReviews();
    }
  }, [pagination]);

  const onPageChange = (page, limitPerPage) => {
    setPagination({ page: page, limit: limitPerPage ? limitPerPage : 20 });
  };

  return (
    <div className="-mt-5">
      {/* Recommended & notes  */}
      <div className="w-full py-10">
        <div className="w-full mb-5">
          <div className="flex justify-between">
            <h2 className="text-3xl font-bold mb-5">Recipient Reviews</h2>
          </div>

          <Reviews reviews={data.data} />
        </div>

        <Pagination
          pageCount={data.total_page}
          itemCount={data.total_items}
          onPageChange={onPageChange}
          showAll={true}
        />
      </div>
    </div>
  );
}
