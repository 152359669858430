import React, { useCallback, useEffect, useState } from "react";

import api from "lib/api";
import Pagination from "components/Pagination";

export default function ModalOrderRecipientsList({ order }) {
  const [data, setData] = useState({});
  const [pagination, setPagination] = useState(null);
  const [orderRecipients, setOrderRecipients] = useState([]);

  const fetchOrderRecipients = useCallback(async () => {
    const { data, err } = await api.controllers.getOrderRecipients(order.id, {
      page: pagination && pagination.page,
      limit: pagination && pagination.limit,
    });
    if (!err) {
      setData(data);
      setOrderRecipients(data);
    }
  }, [pagination, order.id]);

  // const get = async () => {
  //   const { data, err } = await api.controllers.getOrderRecipients(order.id);
  //   if (!err) {
  //     setData(data);
  //     setOrderRecipients(data);
  //   }
  // };

  const onPageChange = (page, limitPerPage) => {
    setPagination({ page: page, limit: limitPerPage ? limitPerPage : 20 });
  };

  useEffect(() => {
    pagination && fetchOrderRecipients();
  }, [pagination, fetchOrderRecipients]);

  let renderRecipients = (_recipients) => {
    return _recipients?.map((recipient, i) => {
      return (
        <div key={recipient.id} className="flex justify-between px-3 py-3 border border-gray-300 my-2 rounded-md">
          <div className="mb-1 w-[2%]"></div>
          <p className="mb-1 w-4/12">{recipient.name || recipient.recipient_name}</p>
          <p className="mb-1 w-4/12">{recipient.email || recipient.recipient_email}</p>
          <p className="mb-1 w-4/12">{recipient.phone_number || recipient.recipient_phone_number}</p>
        </div>
      );
    });
  };

  return (
    <div className="flex flex-wrap mt-4">
      <div className="w-full mb-12 px-4">
        <div className="pt-4 pb-6">
          <h3 className="text-2xl font-bold text-center">Recipients</h3>
        </div>
        <div className="w-full pr-5 mb-2">
          <div className="items-center w-full bg-transparent border-collapse overflow-hidden">
            <div className="bg-tertiary_gray_two px-3 flex justify-between">
              <div className="text-left py-3 font-bold w-[2%]"></div>
              <p className="text-left py-3 font-bold w-4/12">Name</p>
              <p className="text-left py-3 font-bold w-4/12">Email</p>
              <p className="text-left py-3 font-bold w-4/12">Phone Number</p>
            </div>

            <div className="max-h-72 overflow-auto">{renderRecipients(orderRecipients.data)}</div>
          </div>
          <Pagination
            pageCount={data.total_page}
            itemCount={data.total_items}
            onPageChange={onPageChange}
            showAll={true}
          />
        </div>
      </div>
    </div>
  );
}
