import api from "lib/api";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function IntegrationList() {
  const [integrations, setIntegrations] = useState([]);

  const get = async () => {
    const { err, data } = await api.controllers.getIntegrations();

    if (!err) {
      setIntegrations(data.data);
    }
  };

  const types = [
    {
      name: "Slack",
      integration: "slack",
    },
    {
      name: "Talenta",
      integration: "talenta",
    },
    {
      name: "Darwinbox",
      integration: "darwinbox",
    },
  ];

  useEffect(() => {
    get();
  }, []);

  return (
    <div className="p-8 bg-white w-full border text-center">
      <h3 className="text-2xl font-medium">Integrations</h3>

      <div className="mt-8 grid grid-cols-4 gap-4">
        {types.map((type, index) => {
          const integration = integrations.find((_integration) => _integration.integration === type.integration);
          const active = integration ? integration.active : false;

          return (
            <div key={index} className="border rounded-md p-4">
              <img
                src={require(`assets/img/${type.integration}.png`).default}
                alt={type.name}
                className={`w-28 h-28 mx-auto object-contain`}
              />
              <h4 className="mb-2">{type.name}</h4>
              <p
                className={`text-xs mb-5 font-medium py-1 px-2  w-fit mx-auto rounded-full ${
                  active ? "text-green-600 bg-green-100" : "text-red-600 bg-red-100"
                }`}
              >
                <i className={`fas ${active ? "fa-check-circle" : "fa-times-circle"} mr-1`} />{" "}
                {active ? "Active" : "Not Active"}
              </p>
              <Link
                to={`/integrations/${type.integration}`}
                className="w-full block text-white bg-primaryTwo text-sm py-2 font-medium rounded-md"
              >
                Detail
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}
