import React, { useEffect, useState } from "react";

import api from "lib/api";
import CardStats from "components/Cards/CardStats";

export default function OrderStats() {
  const [data, setData] = useState({
    recipient_count: 0,
    address_completed_count: 0,
    processing_count: 0,
    delivering_count: 0,
    delivered_count: 0,
  });

  useEffect(() => {
    const get = async () => {
      const { data: _data, err } = await api.controllers.getOrderSummary();
      if (!err) {
        setData(_data.data);
      }
    };
    get();
  }, []);

  return (
    <div className="mx-auto w-72 mt-12">
      <div>
        {/* Card stats */}
        <div>
          <div className="w-full px-4">
            <CardStats
              statSubtitle="Unclaimed"
              statTitle={`${data.recipient_count - data.address_completed_count}`}
              maxValue={data.recipient_count}
              circleColor="rgba(196, 52, 138, 1)"
              trailColor="rgba(196, 52, 138, 0.5)"
              borderBottom="border-b-pink-600"
            />
          </div>
          <div className="w-full px-4">
            <CardStats
              statSubtitle="Processing"
              statTitle={`${data.processing_count - data.delivering_count}`}
              maxValue={data.processing_count}
              circleColor="rgba(83, 165, 138, 1)"
              trailColor="rgba(83, 165, 138, 0.5)"
              borderBottom="border-b-teal-500"
            />
          </div>
          <div className="w-full px-4">
            <CardStats
              statSubtitle="Delivering"
              statTitle={`${data.delivering_count - data.delivered_count}`}
              maxValue={data.delivering_count}
              circleColor="rgba(234, 196, 81, 1)"
              trailColor="rgba(234, 196, 81, 0.5)"
              borderBottom="border-b-amber-400"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
