import React, { useEffect, useState } from "react";
import api from "lib/api";
import Spinner from "components/Spinner";
import { useError } from "contexts/error";
import Modal from "components/Modal/Modal";
import ModalOrderRecipientsList from "./ModalOrderRecipientsList";
import Map from "components/map/Map";

const OrderPreviewCompany = ({ order, setStep, loadOrder }) => {
  const [products, setProducts] = useState([]);
  const [recipients, setRecipients] = useState("");

  const [loading, setLoading] = useState(false);
  const { handleError } = useError();

  const [showModal, setShowModal] = useState(false);
  const [showRecipientsModal, setShowRecipientsModal] = useState(false);

  let getProducts = async () => {
    const { data, err } = await api.controllers.getOrderProducts(order.id);
    if (!err) {
      setProducts(data.data);
    }
  };
  const giftProducts = products.filter((product) => product.product_category_type === "gift");
  const packagingProducts = products.filter((product) => product.product_category_type === "packaging");
  const decorationProducts = products.filter((product) => product.product_category_type === "decoration");

  let getRecipients = async () => {
    const { data, err } = await api.controllers.getOrderRecipients(order.id);
    if (!err) {
      let _recipients = data.data[0];
      setRecipients(_recipients);
    }
  };

  useEffect(() => {
    loadOrder();
    getProducts();
    getRecipients();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let onSubmit = async () => {
    setLoading(true);
    const { err } = await api.controllers.publishOrder(order.id);
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      setStep("published");
    }
  };

  let modal = (
    <div className="text-center flex flex-col items-center pt-20 pb-6">
      <img
        src={require("assets/img/order-edit/publish-confirm.png").default}
        alt={"publish-confirm"}
        className="w-32 mb-7"
      />

      <h2 className="text-2xl font-bold mb-3">Confirm Publish</h2>

      {order.deliver_to === "company" && (
        <p className="mb-7">
          Please confirm that you want to publish. Once confirmed, we will start processing your order.
        </p>
      )}
      {order.deliver_to !== "company" && (
        <p className="mb-7">
          Please confirm that you want to publish. Once confirmed, we will send notifications to recipients to receive
          the gift. This action cannot be undo.
        </p>
      )}

      <button className="flex bg-primary text-white font-bold px-16 py-3 rounded-lg select-none" onClick={onSubmit}>
        {loading && <Spinner size={4} />} Confirm
      </button>
    </div>
  );

  let renderProducts = (_products) => {
    return _products.map((product) => {
      let imgUrl =
        process.env.REACT_APP_ENVIRONMENT === "production" ? product.product_image_url : "https://picsum.photos/200";
      return (
        <div key={product.id} className="mb-4 flex justify-between">
          <div className="flex pr-4">
            <img src={imgUrl} alt="gambar" className="w-14 h-14 rounded-lg mr-4" />
            <div className={`${product.variations.length > 0 ? "" : "flex items-center"}`}>
              <h4 className="font-medium mb-1">{product.product_name}</h4>
              <div className="">
                {product.variations &&
                  product.variations.map((variation, index) => (
                    <span
                      key={`variation-${index}-${variation.name}`}
                      className="text-sm bg-secondaryTwo px-2 py-1 mr-2 mb-2 rounded-lg"
                    >
                      {variation.option}
                    </span>
                  ))}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      {showRecipientsModal && (
        <Modal
          hideHeader={true}
          title={``}
          widthClass="min-w-[90%]"
          onClose={() => setShowRecipientsModal((prev) => !prev)}
          body={<ModalOrderRecipientsList order={order} onClose={() => setShowModal((prev) => !prev)} />}
        />
      )}

      <div className="bg-white px-5 pt-5 pb-16 rounded-lg border border-tertiary_gray_two mt-10">
        <div className="border-b border-tertiary_gray_two pb-6 mb-10">
          <h3 className="text-2xl font-bold text-center">Preview</h3>
        </div>

        <div className="flex justify-between">
          <div className="w-1/2">
            {/* Items  */}
            <div className="w-full rounded-lg p-4 border border-tertiary_gray_two">
              <h2 className="font-bold mb-4 text-2xl w-fit mx-auto">Items</h2>

              <div className={`${order.type === "personal" ? "" : "mb-5"}`}>
                <h3 className="text-primaryTwo font-bold mb-2">Product</h3>
                {renderProducts(giftProducts)}
              </div>

              <div className="mb-5">
                {packagingProducts.length > 0 && (
                  <div>
                    <h3 className="text-primaryTwo font-bold mb-2">Packaging</h3>
                    {renderProducts(packagingProducts)}
                  </div>
                )}
              </div>

              {decorationProducts.length > 0 && (
                <div>
                  <h3 className="text-primaryTwo font-bold mb-2">Decorations</h3>
                  {renderProducts(decorationProducts)}
                </div>
              )}
            </div>
          </div>

          {/* recipient address  */}
          <div className="w-1/2 rounded-lg p-5 ml-5 text-lg border border-tertiary_gray_two">
            <h2 className="font-bold mb-4 text-2xl w-fit mx-auto">Deliver To</h2>
            <div className="flex mb-6">
              <p className="min-w-140-px font-medium mr-5">Name</p>
              <p className="">: {recipients.recipient_name}</p>
            </div>
            <div className="flex mb-6">
              <p className="min-w-140-px font-medium mr-5">Email</p>
              <p className="">: {recipients.recipient_email}</p>
            </div>
            <div className="flex mb-6">
              <p className="min-w-140-px font-medium mr-5">Phone number</p>
              <p className="">: {recipients.phone_number}</p>
            </div>
            <div className="flex mb-6">
              <p className="min-w-140-px font-medium mr-5">Country</p>
              <p className="">: {recipients.country}</p>
            </div>
            {recipients.country === "indonesia" && (
              <div>
                <div className="flex mb-6">
                  <p className="min-w-140-px font-medium mr-5">City</p>
                  <p className="">: {recipients.city}</p>
                </div>
                <div className="flex mb-6">
                  <p className="min-w-140-px font-medium mr-5">Subsdistrict</p>
                  <p className="">: {recipients.subdistrict}</p>
                </div>
                <div className="flex mb-6">
                  <p className="min-w-140-px font-medium mr-5">Province</p>
                  <p className="">: {recipients.province}</p>
                </div>
              </div>
            )}
            <div className="flex mb-6">
              <p className="min-w-140-px font-medium mr-5">Address</p>
              <p className="">: {recipients.address}</p>
            </div>
            <div className="flex mb-6">
              <p className="min-w-140-px font-medium mr-5">Postal Code</p>
              <p className="">: {recipients.postal_code}</p>
            </div>
            {recipients && <Map values={recipients} readOnly={true} />}
          </div>
        </div>
      </div>

      {/* progress button */}
      <div className="flex justify-center items-center gap-x-5 w-fit mx-auto mb-2 mt-8">
        <button
          className="bg-white text-primary font-bold px-10 py-2 rounded-md border border-primary cursor-pointer select-none w-fit flex"
          onClick={() => setStep("delivery")}
        >
          <img
            src={require("assets/img/arrow-right.svg").default}
            alt={"arrow-left"}
            className="w-6 mr-2 mx-auto rotate-180 svg_orange"
          />
          <span>back</span>
        </button>

        <button
          className="py-3 px-10 w-fit bg-primary text-white font-bold mx-auto flex items-center rounded-lg"
          onClick={() => setShowModal(true)}
        >
          <img src={require("assets/img/order-edit/publish.png").default} alt={"publish"} className="w-6 mr-2" />
          <p>Publish</p>
        </button>
      </div>

      {showModal && (
        <Modal hideHeader={true} title={``} widthClass="w-6/12" body={modal} onClose={() => setShowModal(false)} />
      )}
    </div>
  );
};

export default OrderPreviewCompany;
