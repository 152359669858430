import React, { useState } from "react";
import { Link } from "react-router-dom";
import api from "lib/api";
import Spinner from "components/Spinner";

export default function ResetPassword() {
  const [values, setValues] = useState({ email: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    const { err } = await api.controllers.requestResetPassword(values.email);
    setLoading(false);
    if (!err) {
      setSuccess(true);
    } else {
      setError(err.message);
    }
  };

  return (
    <div className="container mx-auto px-4 h-full">
      <img
        src={require("assets/img/auth/auth-reset-password.png").default}
        alt={"auth-reset-password"}
        className="absolute bottom-0 left-6 w-[32rem]"
      />
      <img
        src={require("assets/img/auth/auth-reset-bricks.svg").default}
        alt={"auth-reset-bricks"}
        className="absolute top-16 right-36 w-24"
      />

      <div className="flex items-center justify-center h-full py-36">
        <div className="relative flex flex-col min-w-0 break-words w-1/2 rounded-lg bg-[#ffffff92] border-0">
          <div className="py-20 px-16">
            <p className="text-primaryTwo text-3xl text-center mb-10 font-bold">Reset Password</p>

            {!success && (
              <form onSubmit={onSubmit}>
                <div className="relative w-full mb-6">
                  <img
                    src={require("assets/img/auth/auth-email.svg").default}
                    alt={"auth-email"}
                    className="w-6 absolute left-3 top-3"
                  />
                  <input
                    name="email"
                    type="email"
                    className="border-0 px-12 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring-1 w-full ease-linear transition-all duration-150"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                  />
                </div>

                {error && <p className="text-center text-red-700 font-medium">{error}</p>}

                <div className="text-center mt-4 mb-6">
                  <button
                    className="bg-primary flex justify-center text-white font-bold py-3 rounded shadow mr-1 mb-1 w-full"
                    type="submit"
                  >
                    {loading && <Spinner />}
                    Reset Password
                  </button>
                </div>
              </form>
            )}
            {success && <p className="text-center">Please check your email to reset password.</p>}

            <div className="text-center mt-4">
              <Link to="/auth/login" className="text-white">
                <p>Back to Sign In</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
