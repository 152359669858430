import React, { useCallback, useEffect, useRef, useState } from "react";
import Spinner from "components/Spinner";
import api from "lib/api";
import { useError } from "contexts/error";
import ModalOrderEditRecipients from "./ModalOrderEditRecipients";
import Modal from "components/Modal/Modal";

const OrderEditRecipients = ({ order, setStep }) => {
  const [tempRecipients, setTempRecipients] = useState([]); //for checkbox
  const [recipients, setRecipients] = useState([]); //raw data
  const [focusInput, setFocusInput] = useState("email");
  const [values, setValues] = useState({
    from: "email",
    emails: "",
    group: "",
  });
  const [loading, setLoading] = useState(false);
  const [failedData, setFailedData] = useState([]);
  const [showExistingRecipientsModal, setShowExistingRecipientsModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const { handleError } = useError();
  const [inputValue, setInputValue] = useState({
    id: Math.random(), //id for checkbox
    name: "",
    email: "",
    phone_number: "",
  });
  const [checkId, setCheckId] = useState([]); //for checkbox
  let arrayAllId = []; //for checkbox
  const ref = useRef();

  // this useEffect, add temporary key select for checkbox recipient data {cant filter in here coz ruined autofocus}
  useEffect(() => {
    setTempRecipients(
      recipients.map((data) => {
        return {
          select: false,
          emailRef: null,
          phoneRef: null,
          id: data.id,
          name: data.recipient_name || data.name,
          email: data.recipient_email || data.email,
          phone_number: data.recipient_phone_number || data.phone_number,
        };
      })
    );
  }, [recipients]);

  // set focus on the dynamic input field
  useEffect(() => {
    if (tempRecipients.length > 0) {
      if (focusInput === "email") {
        tempRecipients[tempRecipients.length - 1].emailRef.focus();
      } else if (focusInput === "phone_number") {
        tempRecipients[tempRecipients.length - 1].phoneRef.focus();
      }
    }
  }, [tempRecipients.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchOrderRecipients = useCallback(async () => {
    const { data, err } = await api.controllers.getOrderRecipients(order.id, { limit: -1 });
    if (!err) {
      let _recipients = data.data;
      setRecipients(_recipients);

      let emails = _recipients.map((recipient) => recipient.recipient_email).join("\n");
      setValues((values) => ({ ...values, emails: emails }));
    }
  }, [order.id]);

  let filterFromEmail = async (emails, phones) => {
    let _emails = emails || values.emails.split(`\n`);
    const { data, err } = await api.controllers.filterRecipients({
      ...(_emails !== null && { emails: _emails.filter((email) => email !== null) }),
      ...(phones !== null && { phone_numbers: phones.filter((phone) => phone !== null) }),
    });
    if (!err) {
      setRecipients(data.data);
    }
  };

  let onFileChange = async (e) => {
    setFailedData([]);

    const { err, data } = await api.controllers.importRecipient({
      file: e.target.files[0],
    });

    if (err) {
      handleError(err);
    } else {
      e.target.value = "";
      let _failedData = data.data.failed_data;
      let _successData = data.data.success_data;
      if (_failedData.length > 0) {
        setFailedData(_failedData);
      } else if (_successData.length > 0) {
        let emails = _successData.map((recipient) => recipient.email);
        let phones = _successData.map((recipient) => recipient.phone_number);
        filterFromEmail(emails, phones);
      }
    }
  };

  let onSubmit = async (e) => {
    setLoading(true);
    let _recipients = recipients.map((recipient) => {
      let name = recipient.name || recipient.recipient_name;
      let email = recipient.email || recipient.recipient_email;
      let phone_number = recipient.phone_number || recipient.recipient_phone_number;

      return {
        name: name,
        ...(email && { email: email }),
        ...(phone_number && { phone_number: phone_number }),
      };
    });

    const { err } = await api.controllers.addOrderRecipients(order.id, _recipients);
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      setStep("message");
    }
  };

  useEffect(() => {
    fetchOrderRecipients();
  }, [fetchOrderRecipients]);

  const handleInputChange = (event) => {
    setInputValue({
      ...inputValue,
      [event.target.name]: event.target.value,
    });
  };

  // if statement to add raw recipient data from input
  // inputValue.name.length > 0 || #noted
  if (inputValue.email.length > 0 || inputValue.phone_number.length > 0) {
    const obj = {};
    obj.id = Math.floor(Math.random() * 1000);
    obj.name = inputValue.name;
    obj.email = inputValue.email;
    obj.phone_number = inputValue.phone_number;

    setRecipients([...recipients, obj]);

    return setInputValue({
      id: Math.floor(Math.random() * 1000),
      name: "",
      email: "",
      phone_number: "",
    });
  }

  const removeRecipient = () => {
    checkId.map((item) =>
      setRecipients((current) =>
        current.filter((recipient) => {
          return parseInt(item.id) !== recipient.id;
        })
      )
    );

    setCheckId([]);
  };

  const handleAllCheckBox = (e) => {
    const { checked } = e.target;

    setTempRecipients(
      tempRecipients.map((d) => {
        d.select = checked;
        return d;
      })
    );

    tempRecipients.map((ref, i) => {
      return ref.select ? (arrayAllId[i] = { id: ref.id }) : arrayAllId;
    });

    checked ? setCheckId([...checkId, ...arrayAllId]) : setCheckId([]);
  };

  const handleEditChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...recipients];
    // const tempList = [...tempRecipients];
    list[index][name] = value;
    // tempList[index][name] = value;

    setRecipients(list);
    // setTempRecipients(tempList);
  };

  const handleOpenFileDialog = (e) => {
    ref.current.click();
  };

  let renderRecipients = (_recipients) => {
    return _recipients?.map((recipient, i) => {
      return (
        <div key={`input - ${i}`} className={`${order.free ? "w-full border border-gray-300 my-2" : ""}`}>
          <div
            className={`flex ${
              order.free ? "w-10/12 mx-auto" : "border border-gray-300 my-2"
            } justify-between px-3 py-1 rounded-md`}
          >
            <div className={`flex justify-start items-center w-1/12 ${order.free ? "hidden" : ""}`}>
              <input
                type="checkbox"
                name={recipient.name}
                checked={recipient.select}
                disabled={order.free}
                className={`${order.free ? "opacity-0" : ""}`}
                onChange={(event) => {
                  let checked = event.target.checked;
                  setTempRecipients(
                    tempRecipients.map((data) => {
                      if (recipient.id === data.id) {
                        data.select = checked;
                      }
                      return data;
                    })
                  );

                  if (checked) {
                    setCheckId([...checkId, { id: event.target.id }]);
                  } else {
                    setCheckId(checkId.filter((item) => item.id !== event.target.id));
                  }
                }}
                id={recipient.id}
              />
            </div>
            <input
              type="text"
              name="name"
              value={
                recipients[i]?.name || recipients[i]?.recipient_name
                  ? recipients[i]?.name || recipients[i]?.recipient_name
                  : ""
              }
              onChange={(e) => handleEditChange(e, i)}
              className={` ${order.free ? "border-x-0 border-t-0 w-3/12" : "w-4/12 border-0"} focus:ring-0`}
            />

            <input
              type="text"
              name="email"
              ref={(e) => tempRecipients.length > 0 && (recipient.emailRef = e)}
              value={
                recipients[i]?.email || recipients[i]?.recipient_email
                  ? recipients[i]?.email || recipients[i]?.recipient_email
                  : ""
              }
              onChange={(e) => handleEditChange(e, i)}
              className={` ${order.free ? "border-x-0 border-t-0 w-[26%]" : "w-4/12 border-0"} focus:ring-0`}
            />
            <input
              type="number"
              name="phone_number"
              ref={(e) => tempRecipients.length > 0 && (recipient.phoneRef = e)}
              value={
                recipients[i]?.phone_number || recipients[i]?.recipient_phone_number
                  ? recipients[i]?.phone_number || recipients[i]?.recipient_phone_number
                  : ""
              }
              onChange={(e) => handleEditChange(e, i)}
              className={` ${order.free ? "border-x-0 border-t-0 w-[26%]" : "w-4/12 border-0"} focus:ring-0`}
            />
          </div>
        </div>
      );
    });
  };

  const downloadModal = (
    <div className="flex mt-4">
      <div className="w-full mb-12 px-4">
        <div className="border-b border-tertiary_gray_two pt-4 pb-6">
          <h3 className="text-2xl font-bold text-center">Download Template</h3>
        </div>

        <div className="px-10">
          <div className="w-full mt-10 pl-8 py-10 mb-2 bg-gray-50 rounded-xl border flex">
            <ul className="w-1/2 flex flex-col gap-y-5">
              <li className="flex gap-x-2">
                <img
                  src={require("assets/img/order-edit/green-check-template.png").default}
                  alt={"green-check-template-icon"}
                  className="w-6 h-6"
                />
                Please use the following template
              </li>
              <li className="flex gap-x-2">
                <img
                  src={require("assets/img/order-edit/green-check-template.png").default}
                  alt={"green-check-template-icon"}
                  className="w-6 h-6"
                />
                Name and Email are required.
              </li>
              <li className="flex gap-x-2">
                <img
                  src={require("assets/img/order-edit/green-check-template.png").default}
                  alt={"green-check-template-icon"}
                  className="w-6 h-6"
                />
                Phone Number, Birthdate, Onboarding date and Groups are not required but recommended.
              </li>
              <li className="flex gap-x-2">
                <img
                  src={require("assets/img/order-edit/green-check-template.png").default}
                  alt={"green-check-template-icon"}
                  className="w-6 h-6"
                />
                Group name is case insensitive. Separate different groups using comma.
              </li>
            </ul>

            <div className="w-1/2 flex flex-col gap-5 items-center">
              <img
                src={require("assets/img/order-edit/download-blue.svg").default}
                alt={"download-blue-icon"}
                className="w-32"
              />
              <a
                href={"/assets/recipients_template.xlsx"}
                target={"_blank"}
                rel="noreferrer"
                className="flex bg-primary py-3 px-16 w-fit text-white font-bold rounded-lg"
              >
                Download
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {showExistingRecipientsModal && (
        <Modal
          hideHeader={true}
          title={``}
          widthClass="min-w-[90%]"
          onClose={() => setShowExistingRecipientsModal((prev) => !prev)}
          body={
            <ModalOrderEditRecipients
              recipients={recipients}
              order={order}
              setRecipients={setRecipients}
              onClose={() => setShowExistingRecipientsModal((prev) => !prev)}
            />
          }
        />
      )}
      {showDownloadModal && (
        <Modal
          hideHeader={true}
          title={``}
          widthClass="w-8/12"
          onClose={() => setShowDownloadModal((prev) => !prev)}
          body={downloadModal}
        />
      )}

      <div className="flex flex-wrap mt-10">
        <div className="w-full mb-12 px-4 bg-white rounded-lg border border-tertiary_gray_two">
          <div className="border-b pt-5 pb-6 mb-12">
            <h3 className="text-2xl font-bold text-center">Recipients</h3>
          </div>

          {order.free && (
            <p className="text-gray-500 text-sm text-center -mt-6 mb-6 border-2 rounded-lg w-fit mx-auto px-5 py-2">
              You can only send to 1 recipient for free campaign
            </p>
          )}

          <div className="w-full pr-5 mb-2">
            {/* button  */}
            <div className="flex justify-between mb-5">
              <div className="flex gap-x-5">
                {checkId.length > 0 && (
                  <button
                    onClick={removeRecipient}
                    className="flex bg-tertiary_red py-3 px-5 w-fit text-white font-bold gap-x-2 rounded-lg"
                  >
                    <img
                      src={require("assets/img/order-edit/trash-can.svg").default}
                      alt={"trash-can-icon"}
                      className="w-6"
                    />{" "}
                    Delete
                  </button>
                )}

                <button
                  onClick={() =>
                    order.free
                      ? handleError({
                          message: "This feature is unavailable for free campaign",
                        })
                      : setShowExistingRecipientsModal((prev) => !prev)
                  }
                  className="flex bg-tertiary_light_blue py-3 px-5 w-fit text-white font-bold gap-x-2 rounded-lg"
                >
                  <img
                    src={require("assets/img/rounded-white-plus.svg").default}
                    alt={"rounded-white-plus-icon"}
                    className="w-6"
                  />{" "}
                  Add Recipients Existing
                </button>
              </div>

              <div className="flex gap-x-5">
                <div
                  onClick={() =>
                    order.free
                      ? handleError({
                          message: "This feature is unavailable for free campaign",
                        })
                      : handleOpenFileDialog()
                  }
                  className="flex bg-white py-3 px-5 w-fit text-tertiary_light_blue font-bold gap-x-2 rounded-lg border border-tertiary_light_blue cursor-pointer"
                >
                  <img src={require("assets/img/order-edit/upload.svg").default} alt={"upload-icon"} className="w-6" />{" "}
                  Upload
                  <input
                    ref={ref}
                    name="file"
                    type="file"
                    className="hidden"
                    onChange={onFileChange}
                    accept=".xls, .xlsx"
                  />
                </div>

                <div
                  onClick={() => setShowDownloadModal((prev) => !prev)}
                  className="flex bg-white py-3 px-5 w-fit text-tertiary_light_blue font-bold gap-x-2 rounded-lg border border-tertiary_light_blue cursor-pointer"
                >
                  <img
                    src={require("assets/img/order-edit/download-blue.svg").default}
                    alt={"download-blue-icon"}
                    className="w-6"
                  />{" "}
                  Template
                </div>
              </div>
            </div>

            {/* upload error  */}
            {failedData.length > 0 && (
              <ul className="text-red-500 text-sm list-disc pl-4 mb-4">
                {failedData.map((failedData) => (
                  <li>
                    {failedData[0].message} at row {failedData[0].index}
                  </li>
                ))}
              </ul>
            )}

            {/* table  */}
            <div className="items-center w-full bg-transparent border-collapse overflow-hidden">
              <div className="bg-tertiary_gray_two px-3 flex justify-between">
                <div className={`text-left py-3 font-bold w-1/12`}>
                  {recipients.length > 0 && (
                    <input
                      type="checkbox"
                      className={`${order.free ? "opacity-0" : ""}`}
                      disabled={order.free}
                      onChange={handleAllCheckBox}
                    />
                  )}
                </div>
                <p className="text-left py-3 font-bold w-4/12 pl-2">Name</p>
                <p className="text-left py-3 font-bold w-4/12 pl-2">Email</p>
                <p className="text-left py-3 font-bold w-4/12 pl-2">Phone Number</p>
              </div>

              {renderRecipients(tempRecipients)}

              <div
                className={`flex justify-between px-3 py-3 my-2 ${order.free && recipients.length > 0 ? "hidden" : ""}`}
              >
                <div className="mb-1 w-1/12"></div>
                <div className="mb-1 w-4/12">
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    required
                    value={inputValue.name}
                    onChange={handleInputChange}
                    className="border-t-0 border-x-0 focus:ring-0"
                  />
                </div>
                <div className="mb-1 w-4/12">
                  {" "}
                  <input
                    type="email"
                    name="email"
                    // autoComplete="off"
                    placeholder="email@example.com"
                    value={inputValue.email}
                    onChange={handleInputChange}
                    onFocus={() => setFocusInput("email")}
                    className="border-t-0 border-x-0 focus:ring-0"
                  />
                </div>
                <div className="mb-1 w-4/12">
                  <input
                    name="phone_number"
                    type="number"
                    placeholder="08xxxxxxxxxx"
                    value={inputValue.phone_number}
                    autoComplete="off"
                    onChange={handleInputChange}
                    min={0}
                    minLength={8}
                    maxLength={13}
                    onFocus={() => setFocusInput("phone_number")}
                    className="border-t-0 border-x-0 focus:ring-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          onClick={onSubmit}
          disabled={recipients.length <= 0}
          className={`${
            recipients.length <= 0 ? "bg-tertiary_gray_two" : " bg-primary"
          } flex gap-x-2 py-3 px-5 text-white font-bold rounded-lg mb-7 w-fit mx-auto`}
        >
          {loading && <Spinner />}
          Message
          <img src={require("assets/img/arrow-right.svg").default} alt={"arrow-right-icon"} className="w-6" />
        </button>
      </div>
    </div>
  );
};

export default OrderEditRecipients;
