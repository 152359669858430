import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import Table from "components/Table/Table.js";
import api from "lib/api";
import mixpanel from "lib/mixpanel";

export default function GroupList() {
  const [data, setData] = useState({});
  const history = useHistory();
  const [pagination, setPagination] = useState(null);

  useEffect(() => {
    mixpanel.track("List Groups");
  }, []);

  useEffect(() => {
    const get = async () => {
      const { data, err } = await api.controllers.getGroups(pagination.page, pagination.limit);
      if (!err) {
        setData(data);
      }
    };
    pagination && get();
  }, [pagination]);

  return (
    <div className="flex flex-wrap mt-4">
      <div className="w-full mb-12 px-4">
        <Table
          settings={{
            title: "Groups",
            button: {
              label: "Add",
              onClick: () => {
                history.push("/groups/new");
              },
            },
            headers: [
              {
                label: "Name",
                render: (element) => {
                  return (
                    <Link to={`/groups/${element.id}`} className={"underline"}>
                      {element.name}
                    </Link>
                  );
                },
              },
              {
                label: "Status",
                render: (element) => {
                  return element.active ? "Active" : "Not Active";
                },
              },
              {
                label: "",
                render: (element) => {
                  return (
                    <Link to={`/groups/${element.id}/recipients`} className={"underline"}>
                      Recipients
                    </Link>
                  );
                },
              },
            ],
          }}
          data={data}
          setPagination={setPagination}
        />
      </div>
    </div>
  );
}
