import Reviews from "components/Reviews/Reviews";
import Spinner from "components/Spinner";
import api from "lib/api";
import { useEffect, useState } from "react";

export default function OrderReviews({ order }) {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let getReviews = async () => {
      setLoading(true);
      const { data, err } = await api.controllers.getOrderReviews(1, -1, {
        order_id: order.id,
      });
      setLoading(false);
      if (!err) {
        setReviews(data.data);
      }
    };
    getReviews();
  }, [order.id]);

  return (
    <div className={``}>
      {loading && (
        <div className="flex justify-center">
          <Spinner color="black" />
        </div>
      )}
      {!loading && <Reviews reviews={reviews} />}
    </div>
  );
}
