import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import api from "lib/api";
import OrderStats from "./Orders/OrderStats";
import mixpanel from "lib/mixpanel";
import OrderRecommended from "./Orders/OrderRecommended";
import OrderNew from "./Orders/OrderNew";
import Reviews from "components/Reviews/Reviews";
import OrderGrid from "./Orders/OrderGrid";
import Spinner from "components/Spinner";

export default function Home() {
  const [data, setData] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [autoNamingCampaign, setAutoNamingCampaign] = useState("");
  const [showModalNewOrder, setShowModalNewOrder] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const history = useHistory();

  const campaignsName = [
    {
      name: "Onboarding",
      img: require("assets/img/home/home-onboarding.png").default,
    },
    {
      name: "Birthday",
      img: require("assets/img/home/home-birthday.png").default,
    },
    {
      name: "VIP",
      img: require("assets/img/home/home-vip.png").default,
    },
    {
      name: "Health Kit",
      img: require("assets/img/home/home-health-kit.png").default,
    },
    {
      name: "Merchandise",
      img: require("assets/img/home/home-merchandise.png").default,
    },
    {
      name: "Anniversary",
      img: require("assets/img/home/home-anniversary.png").default,
    },
    {
      name: "Event",
      img: require("assets/img/home/home-event.png").default,
    },
  ];

  let getOrders = async () => {
    setOrderLoading(true);
    const { data, err } = await api.controllers.getOrders(1, -1, {
      statuses: ["draft", "processing"],
    });
    setOrderLoading(false);
    if (!err) {
      setData(data.data);
    }
  };

  let getReviews = async () => {
    const { data, err } = await api.controllers.getOrderReviews(1, 2);
    if (!err) {
      setReviews(data.data);
    }
  };

  useEffect(() => {
    getReviews();
    mixpanel.track("Home");
  }, []);

  useEffect(() => {
    getOrders();
  }, []);

  return (
    <div className="-mt-5">
      {showModalNewOrder && (
        <OrderNew
          campaignName={autoNamingCampaign}
          onClose={() => {
            setShowModalNewOrder((prev) => !prev);
            setAutoNamingCampaign("");
          }}
        />
      )}

      <div className="flex">
        <div className="w-full mb-12 px-4 my-8">
          <div className="flex justify-between">
            <h2 className="text-3xl font-bold mb-3">Active Campaigns</h2>
          </div>

          {orderLoading && <Spinner color="black" />}
          {!orderLoading && (
            <div>
              {data.length > 0 ? (
                <div>
                  <OrderGrid orders={data} />
                  <div className="flex justify-center mt-8">
                    <button
                      className="font-bold py-2 px-4 text-primary bg-white border border-primary rounded-md"
                      type="button"
                      onClick={() => {
                        history.push("/campaigns");
                      }}
                    >
                      Show More &gt;&gt;
                    </button>
                  </div>
                </div>
              ) : (
                <div className="">
                  <div className="flex flex-col items-center my-4">
                    <img
                      src={require("assets/img/home/home-no-campaign.png").default}
                      alt={"home-no-campaign-icon"}
                      className="w-52 block mb-4"
                    />
                    <p className="font-medium text-2xl mb-3">No Campaigns</p>
                    <p className="text-center">
                      Choose a preset campaign or <br /> create your own.
                    </p>
                  </div>

                  <div className="grid grid-cols-4 gap-3 mt-3">
                    <div
                      className="flex flex-col items-center justify-center rounded-lg p-2 cursor-pointer campaigns_add_bg"
                      onClick={() => setShowModalNewOrder((prev) => !prev)}
                    >
                      <img
                        src={require("assets/img/add-icon.png").default}
                        alt={"home-no-campaign-icon"}
                        className="w-16 block mb-4"
                      />
                      <p className="">Create your own</p>
                    </div>

                    {campaignsName.map((item) => {
                      return (
                        <div
                          key={`campaign ${item.name}`}
                          className="flex flex-col items-center rounded-lg pb-4 cursor-pointer campaigns_option_bg border "
                          onClick={() => {
                            setShowModalNewOrder((prev) => !prev);
                            setAutoNamingCampaign(item.name);
                          }}
                        >
                          <img src={item.img} alt={"home-guide-icon"} className="w-52 block mb-4" />
                          <p className="font-medium">{item.name}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className={`my-8`}>
          <OrderStats />
        </div>
      </div>

      {/* Recommended & notes  */}
      <div className="w-full py-10">
        <div className="w-full mx-auto mb-20">
          <div className="flex justify-between">
            <h2 className="text-3xl font-bold mb-5">Newest Products</h2>
          </div>

          <OrderRecommended />
          <div className="flex justify-center mt-8">
            <Link to={`/gifts`}>
              <div className="font-bold py-2 px-4 text-primary bg-white border border-primary rounded-md">
                Show More &gt;&gt;
              </div>
            </Link>
          </div>
        </div>

        {/* reviews.length > 0 */}
        <div className="w-full">
          <div className="flex justify-between">
            <h2 className="text-3xl font-bold mb-5">Latest Reviews</h2>
          </div>
          <Reviews reviews={reviews} />

          <div className="flex justify-center mt-8">
            <Link to={`/reviews`}>
              <div className="font-bold py-2 px-4 text-primary bg-white border border-primary rounded-md">
                Show More &gt;&gt;
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
