import React, { useEffect, useState } from "react";

import api from "lib/api";
import { useHistory, useParams } from "react-router";
import Spinner from "components/Spinner";
import { useError } from "contexts/error";
import mixpanel from "lib/mixpanel";

export default function EditGroup() {
  const [group, setGroup] = useState(null);
  const [values, setValues] = useState({ name: "", active: false });
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const { handleError } = useError();

  useEffect(() => {
    const get = async () => {
      const { data, err } = await api.controllers.getGroup(id);
      if (!err) {
        const _group = data.data;
        setGroup(_group);
        setValues({ name: _group.name, active: _group.active });

        mixpanel.track("Edit Group", {
          Action: "Show",
          Group: _group.name,
        });
      }
    };
    get();
  }, [id]);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const onCheckboxClicked = (e) => {
    setValues({ ...values, [e.target.name]: !values[e.target.name] });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { err } = await api.controllers.updateGroup(id, values);
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      mixpanel.track("Edit Group", {
        Action: "Success",
        Group: values.name,
      });

      history.push("/groups");
    }
  };

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
      <div className="rounded-t bg-white mb-0 px-6 py-6">
        <div className="text-center flex justify-between">
          <h6 className="text-slate-700 text-xl font-bold">Edit {group && group.name}</h6>
          <button
            className="flex bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="submit"
            onClick={(e) => onSubmit(e)}
          >
            {loading && <Spinner size={4} />} Save
          </button>
        </div>
      </div>
      <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form onSubmit={onSubmit}>
          <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">Group Information</h6>
          <div className="flex flex-wrap w-full lg:w-6/12">
            <div className="w-full lg:w-12/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                  Name{" "}
                  <span className="text-red-500">
                    <sup>*</sup>
                  </span>
                </label>
                <input
                  name="name"
                  type="text"
                  className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Birthday"
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="px-4">
              <div className="flex relative mb-3 items-center">
                <input
                  name="active"
                  type="checkbox"
                  className="border-0 placeholder-slate-300 text-slate-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 mr-2"
                  checked={values.active || values.active === "true" ? true : false}
                  onChange={onCheckboxClicked}
                />
                <label className="block uppercase text-slate-600 text-xs font-bold">Active</label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
