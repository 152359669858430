import React, { useEffect, useState } from "react";

import api from "lib/api";
import { useHistory } from "react-router";
import Spinner from "components/Spinner";
import { useError } from "contexts/error";
import mixpanel from "lib/mixpanel";

export default function AddRecipient() {
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone_number: "",
    birthdate: "",
    onboarding_date: "",
    default_address: "",
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { handleError } = useError();

  useEffect(() => {
    mixpanel.track("Add Recipient", {
      Action: "Show",
    });
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { err } = await api.controllers.createRecipient(values);
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      mixpanel.track("Add Recipient", {
        Action: "Success",
        Recipient: values.name,
      });

      history.push("/recipients");
    }
  };

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
      <div className="rounded-t bg-white mb-0 px-6 py-6">
        <div className="text-center flex justify-between">
          <h6 className="text-slate-700 text-xl font-bold">Add Recipient</h6>
          <button
            className="flex bg-primary text-white font-bold uppercase text-xs px-4 py-2 rounded shadow mr-1"
            type="submit"
            onClick={(e) => onSubmit(e)}
          >
            {loading && <Spinner size={4} />} Save
          </button>
        </div>
      </div>
      <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form onSubmit={onSubmit}>
          <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">Recipient Information</h6>
          <div className="flex flex-wrap w-full lg:w-6/12">
            <div className="w-full lg:w-12/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                  Name{" "}
                  <span className="text-red-500">
                    <sup>*</sup>
                  </span>
                </label>
                <input
                  name="name"
                  type="text"
                  className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Budi"
                  required={true}
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="w-full lg:w-12/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Email address </label>
                <input
                  name="email"
                  type="email"
                  className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="email@example.com"
                  value={values.email}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="w-full lg:w-12/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Phone number </label>
                <input
                  name="phone_number"
                  type="tel"
                  pattern="[0-9]{7,}"
                  className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="08123456789"
                  value={values.phone_number}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="w-full lg:w-12/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Birthdate</label>
                <input
                  name="birthdate"
                  type="date"
                  className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={values.birthdate}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="w-full lg:w-12/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Onboarding Date</label>
                <input
                  name="onboarding_date"
                  type="date"
                  className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={values.onboarding_date}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="w-full lg:w-12/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-slate-600 text-xs font-bold mb-2">Address</label>
                <input
                  name="default_address"
                  type="text"
                  className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  value={values.default_address}
                  placeholder="Jalan ..."
                  required={true}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
