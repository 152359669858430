import React from "react";
import { Link } from "react-router-dom";

const OrderPublished = ({ order }) => {
  return (
    <div className="w-5/12 mx-auto my-20 p-8 bg-white rounded-2xl border border-tertiary_gray_two flex flex-col items-center">
      <img
        src={require("assets/img/order-edit/publish-success.png").default}
        alt={"publish-success"}
        className="w-40"
      />
      <div className="p-4">
        <h2 className="text-3xl text-center mb-2 font-bold">{order.name} Published!</h2>
        <p className="text-center mb-7">
          {!order.recurring && (
            <span>
              {order.deliver_to === "company" && (
                <span>
                  Congratulations, your campaign is successfully published. You can track campaign progress in the
                  detail page.
                </span>
              )}
              {order.deliver_to !== "company" && (
                <span>
                  Congratulations, your campaign is successfully published to {order.recipient_count} recipients. You
                  can track campaign progress in the detail page.
                </span>
              )}
            </span>
          )}
          {order.recurring && (
            <span>
              Congratulations, your recurring campaign is successfully published. You can track campaign progress in the
              detail page.
            </span>
          )}
        </p>
        <div className="flex justify-center">
          <Link
            to={`/orders/${order.id}`}
            className="flex bg-primary text-white font-bold px-16 py-3 rounded-lg cursor-pointer select-none"
          >
            View Detail
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrderPublished;
