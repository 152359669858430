import React from "react";
import { Link } from "react-router-dom";

import UserDropdown from "components/Dropdowns/UserDropdown.js";
import { useAuth } from "contexts/auth";
import MenuDropdown from "components/Dropdowns/MenuDropdown";

export default function Navbar() {
  const { user } = useAuth();
  const logoUrl = (user && user.client_logo_url) || require("assets/img/logo-font.png").default;
  let pathName = window.location.pathname;

  const menus = [
    {
      path: "/",
      label: "Home",
      icon: require("assets/img/nav/nav-home.svg").default,
    },
    {
      path: "/campaigns",
      label: "Campaigns",
      icon: require("assets/img/nav/nav-campaign.svg").default,
    },
    {
      path: "/gifts",
      label: "Catalogue",
      icon: require("assets/img/nav/nav-catalogue.svg").default,
    },
    {
      path: ["/recipients", "/groups"],
      label: "Recipients",
      icon: require("assets/img/nav/nav-recipients.svg").default,
      submenus: [
        {
          path: "/recipients",
          label: "Recipients",
          icon: require("assets/img/nav/nav-recipients.svg").default,
        },
        {
          path: "/groups",
          label: "Groups",
          icon: require("assets/img/nav/nav-recipients.svg").default,
        },
      ],
    },
    {
      path: ["/users", "/integrations"],
      label: "Settings",
      icon: require("assets/img/nav/nav-settings.svg").default,
      submenus: [
        {
          path: "/users",
          label: "Users",
          icon: require("assets/img/nav/nav-settings.svg").default,
        },
        {
          path: "/integrations",
          label: "Integrations",
          icon: require("assets/img/nav/nav-settings.svg").default,
        },
      ],
    },
  ];

  return (
    // className={`${_user.free_gift && query.pathname === "/" ? "mb-10" : ""}`}
    <div>
      {/* Navbar */}
      <nav className="fixed top-0 w-full z-50">
        <div className="flex justify-between  bg-white shadow-lg  px-14 py-4">
          <Link className="block mr-20 text-sm uppercase font-bold" to="/">
            <img alt="Yippy" className="h-10" src={logoUrl} />
          </Link>

          <ul className="flex list-none">
            {menus.map((menu, index) => (
              <li className="relative items-center" key={`menu-${index}`}>
                {!menu.submenus && (
                  <Link
                    className={
                      "flex items-center text-sm uppercase py-3 px-6 font-bold rounded-full nav_svg_parent " +
                      (pathName === menu.path ? "text-primary bg-[#F87F000D]" : "text-slate-700 hover:text-primary")
                    }
                    to={menu.path}
                  >
                    <img
                      src={menu.icon}
                      alt={`menu.icon - ${index}`}
                      className={`w-4 mr-4 ${pathName === menu.path ? "svg_orange" : "nav_svg_child"}`}
                    />
                    {menu.label}
                  </Link>
                )}

                {menu.submenus && <MenuDropdown menu={menu} />}
              </li>
            ))}
          </ul>

          <div className="px-10">
            {/* User */}
            <UserDropdown user={user} />
          </div>
        </div>
      </nav>
      {/* End Navbar */}
    </div>
  );
}
