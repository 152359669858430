import React, { useEffect, useRef, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
//import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import { ErrorProvider } from "contexts/error";
import { useAuth } from "contexts/auth";

import OrderList from "views/admin/Orders/OrderList";
import OrderDetail from "views/admin/Orders/OrderDetail";
// import OrderNew from "views/admin/Orders/OrderNew";
import OrderEdit from "views/admin/Orders/OrderEdit";

import GroupList from "views/admin/Groups/GroupList";
import AddGroup from "views/admin/Groups/AddGroup";
import EditGroup from "views/admin/Groups/EditGroup";
import GroupRecipientList from "views/admin/Groups/GroupRecipientList";

import RecipientList from "views/admin/Recipients/RecipientList";
import AddRecipient from "views/admin/Recipients/AddRecipient";
import EditRecipient from "views/admin/Recipients/EditRecipient";

import UserList from "views/admin/Users/UserList";
import AddUser from "views/admin/Users/AddUser";
import EditUser from "views/admin/Users/EditUser";

import ProductList from "views/admin/Products/ProductList";

import IntegrationList from "views/admin/Integrations/IntegrationList";
import IntegrationSlack from "views/admin/Integrations/IntegrationSlack";
import IntegrationTalenta from "views/admin/Integrations/IntegrationTalenta";
import ReviewsList from "views/admin/Reviews/ReviewsList";
import GuideModal from "views/admin/GuideModal";
import Home from "views/admin/Home";
import IntegrationDarwinbox from "views/admin/Integrations/IntegrationDarwinbox";
import Spinner from "components/Spinner";
import api from "lib/api";
import Cookies from "js-cookie";
import toast from "react-hot-toast";

export default function Admin() {
  const { loading, user } = useAuth();
  const [showModalHelp, setShowModalHelp] = useState(false);
  const [_user, setUser] = useState(user);
  const [_loading, setLoading] = useState(false);

  let query = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!loading && !user) {
      window.location = "/auth/login";
    }
  }, [loading, user]);

  const loadUser = async () => {
    const token = Cookies.get("token");
    if (token) {
      const { err, data } = await api.controllers.me();
      if (!err) {
        setUser(data.data);
      }
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    const { data, err } = await api.controllers.getFreeGift();
    setLoading(false);

    if (err) {
      toast.error(err.message);
    } else {
      history.push(`/orders/edit/${data.data.id}`);
    }
  };

  useEffect(() => {
    if (query.pathname === "/") {
      loadUser();
    }
  }, [query.pathname]);

  function setCookie() {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30);

    const cookieValue = encodeURIComponent(true) + "; expires=" + expirationDate.toUTCString() + "; path=/";
    document.cookie = `free_close=${cookieValue}`;
  }

  function getCookie() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf("free_close") === 0) {
        const cookiePath = cookie.split("=")[1].split(";")[0].trim();
        return cookiePath;
      }
    }

    return null;
  }
  const [showModalFree, setShowModalFree] = useState(getCookie() === "true" ? false : true);

  // function removeCookie() {
  //   return (document.cookie = "free_close=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/");
  // }

  // useEffect(() => {
  //   removeCookie();
  // }, []);

  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModalFree(false);
        getCookie() !== "true" && setCookie();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  return (
    <ErrorProvider>
      {user && (
        <div>
          <div className="relative">
            <AdminNavbar />
            {/* Header */}
            {<HeaderStats />}
            <div className="px-4 md:px-10 mx-auto w-full">
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/campaigns" exact component={OrderList} />
                <Route path="/reviews" exact component={ReviewsList} />
                <Route path="/orders/edit/:id" exact component={OrderEdit} />
                <Route path="/orders/:id" exact component={OrderDetail} />
                <Route path="/groups" exact component={GroupList} />
                <Route path="/groups/new" exact component={AddGroup} />
                <Route path="/groups/:id" exact component={EditGroup} />
                <Route path="/groups/:id/recipients" exact component={GroupRecipientList} />
                <Route path="/recipients" exact component={RecipientList} />
                <Route path="/recipients/new" exact component={AddRecipient} />
                <Route path="/recipients/:id" exact component={EditRecipient} />
                <Route path="/users" exact component={UserList} />
                <Route path="/users/new" exact component={AddUser} />
                <Route path="/users/:id" exact component={EditUser} />
                <Route path="/gifts" exact component={ProductList} />
                <Route path="/integrations" exact component={IntegrationList} />
                <Route path="/integrations/slack" exact component={IntegrationSlack} />
                <Route path="/integrations/talenta" exact component={IntegrationTalenta} />
                <Route path="/integrations/darwinbox" exact component={IntegrationDarwinbox} />
              </Switch>
            </div>
          </div>

          {_user?.free_gift && query.pathname === "/" ? (
            <div>
              <div
                className={`${
                  showModalFree ? "block" : "hidden"
                } fixed bg-[#0000006f] w-screen h-screen top-0 z-50 flex justify-center items-center`}
              >
                <div ref={modalRef} className="bg-white relative w-5/12 h-fit overflow-hidden rounded-2xl flex">
                  <img
                    onClick={() => {
                      getCookie() !== "true" && setCookie();
                      setShowModalFree((prev) => !prev);
                    }}
                    src={require("assets/img/free-gift/close-modal-icon.png").default}
                    alt="close-modal-icon"
                    className="min-w-[16px] min-h-[16px] absolute top-2 right-2 cursor-pointer p-4"
                  />

                  <img
                    src={require("assets/img/free-gift/free-modal-image.png").default}
                    alt="free-modal-imag"
                    className="w-5/12 object-cover"
                  />

                  <div className="py-10 lg:py-12 2xl:py-20 px-12">
                    <p className="font-bold text-lg lg:text-xl mb-2">Your first gift is on us!</p>
                    <p className="mb-6 text-sm lg:text-base text-gray-500">
                      Send your first gift to your client, employee or even yourself. Free!
                    </p>
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        onSubmit();
                        getCookie() !== "true" && setCookie();
                      }}
                      className="cursor-pointer py-4 px-8 w-fit bg-primary rounded-full font-bold text-center text-white"
                    >
                      {_loading ? (
                        <div className="w-fit mx-auto">
                          <Spinner color="white" />
                        </div>
                      ) : (
                        "Send Gift"
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div
                onClick={() => setShowModalFree((prev) => !prev)}
                className={`${showModalFree ? "hidden" : "block"} fixed bottom-4 right-4 cursor-pointer`}
              >
                <div className="bg-amber-100 relative w-fit h-fit overflow-hidden rounded-lg flex">
                  <div className="w-60 h-60 p-2 border-2 border-dashed border-primary rounded-full absolute -right-28 -top-28">
                    <div className="w-full h-full bg-primary rounded-full"></div>
                  </div>

                  <p className="font-bold text-xl text-primary pl-6 py-12">
                    Claim <br /> Free Gift
                  </p>
                  <div className="relative">
                    <img
                      src={require("assets/img/free-gift/free-icon.png").default}
                      alt="free-icon"
                      className="w-36 h-36 "
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              {/* help button  */}
              <div
                className="fixed rounded-full bottom-4 right-4 font-bold py-2 px-4 bg-primary text-white cursor-pointer "
                onClick={() => setShowModalHelp((prev) => !prev)}
              >
                ?
              </div>
              {showModalHelp && <GuideModal onClose={() => setShowModalHelp((prev) => !prev)} />}
            </div>
          )}
        </div>
      )}
    </ErrorProvider>
  );
}
