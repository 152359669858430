import Spinner from "components/Spinner";
import api from "lib/api";
import { useEffect, useState } from "react";

export default function OrderSchedule({ order }) {
  const [currentDay, setCurrentDay] = useState(new Date().getDate());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const [day, setDay] = useState("");

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState({});
  const [currentWeeks, setCurrentWeeks] = useState([]);
  const [disableBack, setDisableBack] = useState(true);
  const [loadingRecipients, setLoadingRecipients] = useState(false);

  const stringifyDate = (date) => {
    return date.toString().length === 1 ? `0${date}` : date;
  };

  function isBefore(date1, date2) {
    return date1 < date2;
  }

  const getWeeks = (yyyy, m, d) => {
    let stringCurrentDay = stringifyDate(d);
    let stringCurrentMonth = stringifyDate(m);

    let givenDateString = `${yyyy}-${stringCurrentMonth}-${stringCurrentDay}`;

    // Parse the given date string
    const [year, month, day] = givenDateString.split("-");
    const givenDate = new Date(year, month - 1, day);

    // Get the day of the week (0-6, where 0 represents Sunday)
    const dayOfWeek = givenDate.getDay();

    // Calculate the start and end dates of the week
    const startDate = new Date(givenDate);
    startDate.setDate(givenDate.getDate() - (dayOfWeek - 2)); // Start from monday
    const endDate = new Date(givenDate);
    endDate.setDate(givenDate.getDate() + (8 - dayOfWeek)); // End on sunday

    // Generate the list of dates within the same week
    const dateList = [];
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      dateList.push(currentDate.toISOString().slice(0, 10));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateList;
  };

  useEffect(() => {
    let stringCurrentDay = stringifyDate(currentDay);
    let stringCurrentMonth = stringifyDate(currentMonth);

    let givenDateString = `${currentYear}-${stringCurrentMonth}-${stringCurrentDay}`;
    setDay(givenDateString);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // first render get current list of date in this week
  useEffect(() => {
    if (day.length > 0) {
      setCurrentWeeks(getWeeks(currentYear, currentMonth, currentDay));
    }
  }, [day]); // eslint-disable-line react-hooks/exhaustive-deps

  const getOrderSchedule = async () => {
    setLoadingRecipients(true);
    const { data, err } = await api.controllers.getOrderSchedule(order.id, {
      start_date: currentWeeks[0],
      end_date: currentWeeks[6],
    });
    if (!err) {
      setData(data.data);
    }
    setLoadingRecipients(false);
  };

  // checking prev button will disable or not
  useEffect(() => {
    if (currentWeeks.length > 0) {
      getOrderSchedule();

      const d1 = new Date(currentWeeks[0]);
      const d2 = new Date(day);

      setDisableBack(isBefore(d1, d2));
    }
  }, [currentWeeks]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePrevWeek = () => {
    const previousWeek = new Date(currentWeeks[0]);
    previousWeek.setDate(previousWeek.getDate() - 7);
    const split = previousWeek.toISOString().slice(0, 10).split("-");

    const d1 = new Date(previousWeek.toISOString().slice(0, 10));
    const d2 = new Date(day);

    if (isBefore(d1, d2)) {
      const splitToday = day.split("-");
      setCurrentDay(Number(splitToday[2]));
      setCurrentMonth(Number(splitToday[1]));
      setCurrentYear(Number(splitToday[0]));
      setCurrentWeeks(getWeeks(Number(splitToday[0]), Number(splitToday[1]), Number(splitToday[2])));
    } else {
      setCurrentDay(Number(split[2]));
      setCurrentMonth(Number(split[1]));
      setCurrentYear(Number(split[0]));
      setCurrentWeeks(getWeeks(Number(split[0]), Number(split[1]), Number(split[2])));
    }
  };

  const handleNextWeek = () => {
    const nextWeek = new Date(currentWeeks[0]);
    nextWeek.setDate(nextWeek.getDate() + 7);
    const split = nextWeek.toISOString().slice(0, 10).split("-");

    setCurrentDay(Number(split[2]));
    setCurrentMonth(Number(split[1]));
    setCurrentYear(Number(split[0]));
    setCurrentWeeks(getWeeks(Number(split[0]), Number(split[1]), Number(split[2])));
  };

  useEffect(() => {
    if (data.length > 0) {
      setFilteredData(
        data.filter((item) => {
          let stringCurrentDay = stringifyDate(currentDay);
          let stringCurrentMonth = stringifyDate(currentMonth);

          let givenDateString = `${currentYear}-${stringCurrentMonth}-${stringCurrentDay}`;
          return item.date === givenDateString;
        })[0]
      );
    }
  }, [data, currentDay]); // eslint-disable-line react-hooks/exhaustive-deps

  function formatDate(dateString) {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }

  return (
    <div>
      {/* title current month  */}
      <div className=" text-neutral-800 text-xl font-bold leading-7 mt-3 mb-8">
        {currentWeeks
          .filter((week) => {
            const weekSplit = week.split("-");
            let stringCurrentDay = stringifyDate(currentDay);
            // eslint-disable-next-line
            return weekSplit[2] == stringCurrentDay;
          })
          .map((day) => {
            const date = new Date(day);

            const options = { year: "numeric", month: "long" };
            return date.toLocaleDateString("en-US", options);
          })}
      </div>

      <div className="justify-center items-center gap-4 flex">
        {/* prev week */}
        <button
          disabled={disableBack}
          className={`rounded-full p-3  ${disableBack ? "select-none filter grayscale" : "hover:bg-gray-100"}`}
          onClick={handlePrevWeek}
        >
          <img src={require("assets/img/testy-arrow.svg").default} alt="arrow" className="w-4 h-4" />
        </button>

        {/* weekly date */}
        {currentWeeks.map((week, index) => {
          const weekSplit = week.split("-");

          const d1 = new Date(week);
          const d2 = new Date(day);

          let expiredDate = isBefore(d1, d2);

          let stringCurrentDay = stringifyDate(currentDay);

          return (
            <div
              key={week}
              onClick={() => {
                !expiredDate && setCurrentDay(Number(weekSplit[2]));
                !expiredDate && setCurrentMonth(Number(weekSplit[1]));
                !expiredDate && setCurrentYear(Number(weekSplit[0]));
              }}
              className={`relative grow shrink basis-0 px-6 py-3 ${
                expiredDate
                  ? "bg-zinc-100 text-stone-300"
                  : // eslint-disable-next-line
                  weekSplit[2] == stringCurrentDay
                  ? "bg-sky-50 border border-sky-300 text-sky-400"
                  : "cursor-pointer border border-stone-300"
              } rounded-lg flex-col justify-center items-center gap-2 inline-flex`}
            >
              {!expiredDate && !loadingRecipients && data && (
                <div
                  className={`absolute -top-2 -right-2 text-white font-semibold px-2 py-1 bg-primary rounded-full text-xs`}
                >
                  {data[index]?.recipients.length}
                </div>
              )}

              <div className=" text-lg font-bold leading-7">{weekSplit[2]}</div>
              <div className=" text-sm font-medium leading-tight">
                {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"][index]}
              </div>
            </div>
          );
        })}

        {/* next week */}
        <button className="rounded-full py-2 px-3 text-gray-500 hover:bg-gray-100" onClick={handleNextWeek}>
          <img
            src={require("assets/img/testy-arrow.svg").default}
            alt="arrow"
            className="w-4 h-4 transform scale-x-[-1]"
          />
        </button>
      </div>

      {loadingRecipients && (
        <div className="w-fit mx-auto my-20">
          <Spinner color="black" />
        </div>
      )}

      {!loadingRecipients && (
        <div>
          {filteredData?.recipients?.length > 0 ? (
            <div>
              <div className=" text-neutral-800 text-base font-normal leading-normal mt-9 mb-8">
                The following recipients will be notified on {formatDate(filteredData.date)} :
              </div>

              {/* list recipients  */}
              {filteredData?.recipients.map((recipient) => (
                <div key={recipient.id} className="p-5 mb-4 rounded-md border border-gray-200 grid grid-cols-3 w-full">
                  <div className=" justify-start items-center gap-2 flex">
                    <div className="w-4 h-4 relative" />
                    <div className="text-neutral-800 text-base font-normal leading-snug tracking-wide">
                      <i className="fas fa-user mr-4"></i>
                      {recipient.name}
                    </div>
                  </div>

                  <div className=" justify-start items-center gap-2 flex">
                    <div className="w-4 h-4 relative" />
                    <div className="text-neutral-800 text-base font-normal leading-snug tracking-wide">
                      {recipient.phone_number}
                    </div>
                  </div>

                  <div className=" justify-start items-center gap-2 flex">
                    <div className="w-4 h-4 relative" />
                    <div className="text-neutral-800 text-base font-normal leading-snug tracking-wide">
                      {recipient.email}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="bg-gray-50 mt-9 mb-8 py-20">
              <img
                src={require("assets/img/detail-notif-empty.png").default}
                alt="detail-notif-empty"
                className="w-36 h-36 mx-auto mb-6"
              />
              {/* No items found  */}
              <p className="text-center text-neutral-500 text-lg leading-7">
                There's no recipients to be notify on this date
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
