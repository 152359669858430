import api from "lib/api";
import { useEffect, useState } from "react";

export default function OrderProducts({ order }) {
  const [products, setProducts] = useState([]);

  const getOrderProducts = async (id) => {
    const { data, err } = await api.controllers.getOrderProducts(id);
    if (!err) {
      setProducts(data.data);
    }
  };

  useEffect(() => {
    order && getOrderProducts(order.id);
  }, [order]);

  const giftProducts = products.filter((product) => product.product_category_type === "gift");
  const packagingProducts = products.filter((product) => product.product_category_type === "packaging");
  const decorationProducts = products.filter((product) => product.product_category_type === "decoration");

  let renderProducts = (title, products) => {
    return (
      <div>
        <h2 className="text-lg font-bold mb-4">{title}</h2>
        <div className="grid grid-cols-5 gap-4">
          {products.map((product) => {
            let imgUrl =
              process.env.REACT_APP_ENVIRONMENT === "production"
                ? product.product_image_url
                : "https://picsum.photos/200";

            return (
              <div key={product.id} className="bg-white rounded-lg shadow-lg">
                <div className="w-full">
                  <img src={imgUrl} alt={product.product_name} className="w-full rounded-t-lg" />
                </div>
                <div className="p-4">
                  <h3 className="mb-2">{product.product_name}</h3>
                  <div className="flex flex-wrap">
                    {product.variations &&
                      product.variations.map((variation, index) => (
                        <span
                          key={`variation-${index}-${variation.name}`}
                          className="text-sm bg-yellow-100 text-yellow-500 px-2 py-1 mr-2 mb-2 rounded-lg"
                        >
                          {variation.option}
                        </span>
                      ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="w-full">
      <div className="mb-4">{renderProducts("Items", giftProducts)}</div>
      {packagingProducts.length > 0 && <div className="mb-4">{renderProducts("Packaging", packagingProducts)}</div>}
      {decorationProducts.length > 0 && <div className="mb-4">{renderProducts("Decorations", decorationProducts)}</div>}
    </div>
  );
}
