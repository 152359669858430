import React, { useState } from "react";
import Spinner from "components/Spinner";
import api from "lib/api";
import { useError } from "contexts/error";

const OrderPersonalItems = ({ order, setStep, loadOrder }) => {
  const { handleError } = useError();

  const [valuesType, setValuesType] = useState({
    personal_type: order.personal_type,
  });
  const [loading, setLoading] = useState(false);

  const onPersonalUpdate = async () => {
    setLoading(true);
    const { err } = await api.controllers.patchOrder(order.id, valuesType);
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      loadOrder();
      setStep("item");
    }
  };

  return (
    <div className="w-full bg-white mt-10 mb-5 pt-4 pb-8 rounded-lg">
      <img
        src={require("assets/img/order-edit/gift-to-recipient.png").default}
        alt={"new-order-type-icon"}
        className="w-40 mx-auto mb-3"
      />

      <h4 className="block font-bold text-center text-3xl w-5/12 mx-auto">What items can your recipients choose?</h4>

      <div className="px-4 my-5 grid grid-cols-2 w-7/12 mx-auto gap-x-12">
        {/* Items based */}
        <div
          onClick={() => setValuesType({ ...valuesType, personal_type: "items" })}
          className={`flex items-center justify-center cursor-pointer px-5 mb-2 mt-4 rounded-lg bg-neutral-100   ${
            valuesType.personal_type === "items" ? "border border-indigo-300" : ""
          }`}
        >
          <div className="block text-center pb-3 px-3">
            <img
              src={require("assets/img/order-edit/gift-item.png").default}
              alt={"gift-item"}
              className="w-12 mx-auto py-4"
            />
            <h3 className="font-bold mb-1">Items Based</h3>
            <p className="text-sm">Items can be customized with company logo.</p>
          </div>
        </div>

        {/* budget based */}
        <div
          onClick={() => {
            setValuesType({ ...valuesType, personal_type: "budget" });
          }}
          className={`flex items-center justify-center cursor-pointer px-5 mb-2 mt-4 rounded-lg bg-neutral-100   ${
            valuesType.personal_type === "budget" ? "border border-indigo-300" : ""
          }`}
        >
          <div className="block text-center pb-3 px-3">
            <img
              src={require("assets/img/order-edit/gift-budget.png").default}
              alt={"gift-budget"}
              className="w-12 mx-auto py-4"
            />
            <h3 className="font-bold mb-1">Budget Based</h3>
            <p className="text-sm">
              Recipients will get the freedom of choice based on your budget or recommended items
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center w-fit mx-auto mb-2 mt-8">
        <div
          className="py-3 px-10 w-fit bg-primary text-white font-bold cursor-pointer mx-auto flex items-center rounded-lg"
          onClick={onPersonalUpdate}
        >
          {loading && <Spinner size={4} />} Next
          <img
            src={require("assets/img/arrow-right.svg").default}
            alt={"home-no-campaign-icon"}
            className="w-4 h-4 ml-2 "
          />
        </div>
      </div>
    </div>
  );
};

export default OrderPersonalItems;
