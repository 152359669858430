import React from "react";
import PropTypes from "prop-types";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function CardStats({
  statSubtitle,
  statTitle,
  statArrow,
  statPercent,
  statPercentColor,
  statDescripiron,
  statIconName,
  statIconColor,
  maxValue,
  circleColor,
  trailColor,
  borderBottom,
  border,
}) {
  return (
    <div
      className={`${border} relative flex flex-col bg-white min-w-0 break-words rounded-xl mb-2 border-b-4 ${borderBottom}`}
    >
      <div className="flex-auto p-4">
        <div className="flex justify-center ">
          <div className="relative pr-4 w-20 font-bold">
            <CircularProgressbar
              value={statTitle}
              maxValue={maxValue}
              text={`${statTitle}`}
              styles={buildStyles({
                // Text size
                textSize: "30px",
                textColor: `${circleColor}`,
                trailColor: `${trailColor}`,
                pathColor: `${circleColor}`,
              })}
            />
          </div>

          <div className="relative w-auto pl-4 flex justify-center items-center">
            <h5 className="text-xl font-medium">{statSubtitle}</h5>
          </div>
        </div>
        {/*
          <p className="text-sm text-slate-400 mt-4">
            <span className={statPercentColor + " mr-2"}>
              <i
                className={
                  statArrow === "up"
                    ? "fas fa-arrow-up"
                    : statArrow === "down"
                    ? "fas fa-arrow-down"
                    : ""
                }
              ></i>{" "}
              {statPercent}%
            </span>
            <span className="whitespace-nowrap">{statDescripiron}</span>
          </p>
            */}
      </div>
    </div>
  );
}

CardStats.defaultProps = {
  statSubtitle: "Traffic",
  statTitle: "350,897",
  statArrow: "up",
  statPercent: "3.48",
  statPercentColor: "text-emerald-500",
  statDescripiron: "Since last month",
  statIconName: "far fa-chart-bar",
  statIconColor: "bg-red-500",
};

CardStats.propTypes = {
  statSubtitle: PropTypes.string,
  statTitle: PropTypes.string,
  statArrow: PropTypes.oneOf(["up", "down"]),
  statPercent: PropTypes.string,
  // can be any of the text color utilities
  // from tailwindcss
  statPercentColor: PropTypes.string,
  statDescripiron: PropTypes.string,
  statIconName: PropTypes.string,
  // can be any of the background color utilities
  // from tailwindcss
  statIconColor: PropTypes.string,
};
