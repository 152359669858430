import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const MenuDropdown = ({ menu }) => {
  let pathName = window.location.pathname;
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const dropdownPopover = () => {
    setDropdownPopoverShow((prev) => !prev);
  };

  const submenusRef = useRef();

  useEffect(() => {
    const closeSubMenu = (e) => {
      if (!submenusRef?.current?.contains(e.target)) {
        setDropdownPopoverShow(false);
      }
    };

    document.body.addEventListener("click", closeSubMenu);

    return () => {
      document.body.removeEventListener("click", closeSubMenu);
    };
  }, [submenusRef]);

  return (
    <div>
      <div
        ref={submenusRef}
        className={
          "flex items-center text-sm uppercase py-3 px-6 font-bold rounded-full cursor-pointer nav_svg_parent " +
          (pathName === menu.path[0] || pathName === menu.path[1]
            ? "text-primary bg-[#F87F000D]"
            : "text-slate-700 hover:text-primary")
        }
        onClick={() => {
          dropdownPopover();
        }}
      >
        <img
          src={menu.icon}
          alt="menu-icon"
          className={`w-4 mr-4 ${
            pathName === menu.path[0] || pathName === menu.path[1] ? "svg_orange" : "nav_svg_child"
          }`}
        />
        {menu.label}
      </div>

      <div
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow min-w-48 absolute"
        }
      >
        {menu.submenus.map((submenu, index) => (
          <div key={`submenu-${index}`}>
            <Link
              to={submenu.path}
              onClick={() => setDropdownPopoverShow((prev) => !prev)}
              className={
                "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700 hover:text-primary"
              }
            >
              {submenu.label}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuDropdown;
