import React, { useEffect, useRef, useState } from "react";
import compress from "lib/compress";
import api from "lib/api";
import Spinner from "components/Spinner";
import { useError } from "contexts/error";
import { Link } from "react-router-dom";
import Modal from "components/Modal/Modal";

const OrderMessage = ({ order, setStep }) => {
  // const [recipients, setRecipients] = useState([]); //@TODO: replace John with uploaded recipient

  const [showLanguageOption, setShowLanguageOption] = useState(false);
  const [values, setValues] = useState({
    notification_lang: "id",
    notification_image: null,
    notification_title: order.notification_title || "",
    notification_body: order.notification_body || "",
    notification_action: order.notification_action || "",
  });

  const [wrapValue, setWrapValue] = useState({
    gift_wrapping: order.gift_wrapping,
  });
  const [patchLoading, setPatchLoading] = useState(false);
  const [wrapping, setWrapping] = useState(false);
  const [previewWrapping, setPreviewWrapping] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [wrapGif, setWrapGif] = useState("");
  const [integration, setIntegration] = useState({ active: false });
  const notificationOption = {
    email_notification: true,
    whatsapp_notification: true,
  };
  const [loading, setLoading] = useState(false);
  const { handleError } = useError();
  const [preview, setPreview] = useState({});
  const ref = useRef();

  let defaultTitle = {
    id: `{{ name }}, Kamu Mendapatkan Gift dari ${order.client_name}`,
    en: `{{ name }}, You Got a Gift from ${order.client_name}`,
  };
  let defaultBody = {
    id: `Hi {{ name }},\n${order.client_name} mengirimkan kamu gift. Silahkan konfirmasi alamat untuk menerima.\nP.S: Untuk memudahkan proses pengiriman gift, mohon konfirmasi maksimal 2 hari sejak pesan ini diterima.\nTerima Kasih.`,
    en: `Hi {{ name }},\n${order.client_name} is sending you a gift. Please confirm your address to claim the gift.\nP.S: To smoothen the process of sending you the gifts, please confirm to us max. 2 days after you received this message.\nThank You.`,
  };
  let defaultAction = {
    id: "Klaim",
    en: "Claim",
  };

  let nameRegex = /{{\s?name\s?}}/g;
  let companyRegex = /{{\s?company\s?}}/g;

  let compile = (text) => {
    return text.replaceAll(nameRegex, "John").replaceAll(companyRegex, order.client_name).replaceAll("\n", "<br />");
  };

  useEffect(() => {
    if (order.activate) {
      setWrapping(true);
    }
  }, [order]);

  useEffect(() => {
    if (values.notification_lang) {
      setPreview({
        image: order.notification_image_url,
        title: compile(values.notification_title || defaultTitle[values.notification_lang]),
        body: compile(values.notification_body || defaultBody[values.notification_lang]),
        action: values.notification_action || defaultAction[values.notification_lang],
      });
    }
  }, [values.notification_lang]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (values.notification_image) {
      setPreview((preview) => ({
        ...preview,
        image: URL.createObjectURL(values.notification_image),
      }));
    }
  }, [values.notification_image]);

  useEffect(() => {
    if (values.notification_title) {
      setPreview((preview) => ({
        ...preview,
        title: compile(values.notification_title),
      }));
    }
  }, [values.notification_title]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (values.notification_body) {
      setPreview((preview) => ({
        ...preview,
        body: compile(values.notification_body),
      }));
    }
  }, [values.notification_body]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (values.notification_action) {
      setPreview((preview) => ({
        ...preview,
        action: values.notification_action,
      }));
    }
  }, [values.notification_action]);

  useEffect(() => {
    setPreview({
      image: order.notification_image_url,
      title: compile(values.notification_title || defaultTitle[values.notification_lang]),
      body: compile(values.notification_body || defaultBody[values.notification_lang]),
      action: values.notification_action || defaultAction[values.notification_lang],
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  let onFileChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.files[0] });
  };

  let onSubmit = async () => {
    setLoading(true);
    const compressedImage = values.notification_image ? await compress(values.notification_image) : null;
    const { err } = await api.controllers.updateOrderNotification(order.id, {
      notification_lang: values.notification_lang,
      notification_image: compressedImage,
      notification_title: values.notification_title,
      notification_body: values.notification_body,
      notification_action: values.notification_action,
    });
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      setWrapping(true);
    }
  };

  let onWrapUpdate = async () => {
    setPatchLoading(true);
    const { err } = await api.controllers.patchOrder(order.id, wrapValue);
    setPatchLoading(false);

    if (err) {
      handleError(err);
    } else {
      setStep("preview");
    }
  };

  let onPublish = async () => {
    const { err } = await api.controllers.publishOrder(order.id);

    if (err) {
      handleError(err);
    } else {
      setStep("published");
    }
  };

  useEffect(() => {
    const get = async () => {
      const { err, data } = await api.controllers.getIntegration("slack");
      if (!err) {
        setIntegration(data.data);
      }
    };

    get();
  }, []);

  const handleOpenFileDialog = (e) => {
    ref.current.click();
  };

  const body = (
    <div>
      {wrapGif === "scratch" && (
        <img src={require("assets/img/order-edit/scratch.gif").default} alt={"scratch"} className="w-full" />
      )}
      {wrapGif === "openbox" && (
        <img src={require("assets/img/order-edit/openbox.gif").default} alt={"scratch"} className="w-full" />
      )}
    </div>
  );

  let publishModal = (
    <div className="text-center flex flex-col items-center pt-20 pb-6">
      <img
        src={require("assets/img/order-edit/publish-confirm.png").default}
        alt={"publish-confirm"}
        className="w-32 mb-7"
      />

      <h2 className="text-2xl font-bold mb-3">Confirm Publish</h2>

      <p className="mb-7">
        Please confirm that you want to publish. Once confirmed, we will send notifications to recipients to receive the
        gift. This action cannot be undo.
      </p>

      <button className="flex bg-primary text-white font-bold px-16 py-3 rounded-lg select-none" onClick={onPublish}>
        {loading && <Spinner size={4} />} Confirm
      </button>
    </div>
  );

  const img = preview.image || "https://yippy.s3.ap-southeast-1.amazonaws.com/static/email-gifts.jpeg";

  return (
    <div>
      {showPublishModal && (
        <Modal
          hideHeader={true}
          title={``}
          widthClass="w-6/12"
          body={publishModal}
          onClose={() => setShowPublishModal(false)}
        />
      )}

      {!order.activate && !wrapping && (
        <div>
          <div className="bg-white p-9 rounded-lg border border-tertiary_gray_two mt-10">
            <div className="border-b border-tertiary_gray_two pb-6 mb-12">
              <h3 className="text-2xl font-bold text-center">Add Message</h3>
            </div>

            {/* language button  */}
            <div className="relative">
              <button
                onClick={() => setShowLanguageOption((prev) => !prev)}
                className={`text-primaryTwo font-medium flex justify-between items-center gap-4 py-2 px-4 w-48 rounded-md 
            ${showLanguageOption ? "bg-primaryTwo text-slate-50" : " bg-gray-100 "}`}
              >
                {/* Select Language */}
                {values.notification_lang === "id" ? "Indonesia (ID)" : "English (EN)"}
                <img
                  src={require("assets/img/order-edit/accordion-arrow.svg").default}
                  alt={"accordion-arrow"}
                  className={`w-4 ${showLanguageOption ? "rotate-180 svg_white" : ""}`}
                />
              </button>
              {showLanguageOption && (
                <div className="absolute top-14 left-0 rounded-md shadow w-48">
                  <button
                    onClick={() => {
                      setValues({ ...values, notification_lang: "id" });
                      setShowLanguageOption((prev) => !prev);
                    }}
                    className="py-2 px-4 font-medium text-primaryTwo"
                  >
                    Indonesia (ID)
                  </button>
                  <button
                    onClick={() => {
                      setValues({ ...values, notification_lang: "en" });
                      setShowLanguageOption((prev) => !prev);
                    }}
                    className="py-2 px-4 font-medium text-primaryTwo"
                  >
                    English (EN)
                  </button>
                </div>
              )}
            </div>

            {/* body input  */}
            <div className="w-7/12 mx-auto p-10 bg-[#FBE6A380] rounded-lg border border-primary">
              <div className="w-full">
                <div className="relative w-full mb-3">
                  <img
                    src={require("assets/img/order-edit/pen-edit.svg").default}
                    alt={"pen-edit-icon"}
                    className="w-4 absolute top-4 right-4 svg_gray"
                  />
                  <input
                    name="notification_title"
                    type="text"
                    className="border border-tertiary_gray_two focus:ring-0 border-dashed pl-3 pr-14 py-3 placeholder-slate-400 text-slate-600 bg-white rounded focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder={defaultTitle[values.notification_lang]}
                    value={values.notification_title}
                    onChange={handleChange}
                    disabled={order.status !== "draft"}
                  />
                </div>
              </div>

              <div className="w-full">
                {/* should be label */}
                <div className="relative" htmlFor="image_upload">
                  <div className="group relative">
                    <img
                      src={require("assets/img/order-edit/pen-edit.svg").default}
                      alt={"pen-edit-icon"}
                      className="w-4 absolute top-4 right-4 svg_gray group-hover:hidden"
                    />
                    <img
                      src={img}
                      alt="Yippy"
                      className="w-full block mb-4 rounded-md border border-tertiary_gray_two border-dashed"
                    />
                    <div
                      onClick={handleOpenFileDialog}
                      className="absolute top-0 bottom-0 right-0 left-0 bg-[#000000a1] rounded-md hidden group-hover:flex cursor-pointer justify-center items-center"
                    >
                      <img
                        src={require("assets/img/order-edit/pen-edit.svg").default}
                        alt={"pen-edit-icon"}
                        className="w-4 absolute top-4 right-4 hidden group-hover:block"
                      />
                      <p className="bg-gray-50 px-6 py-2 rounded-full">Replace Image</p>
                      <p className="absolute bottom-4 left-4 text-xs text-white">PNG, JPG up to 2MB</p>
                    </div>
                  </div>

                  <div className="relative w-full mb-3">
                    <input
                      ref={ref}
                      name="notification_image"
                      type="file"
                      className="hidden"
                      onChange={onFileChange}
                      accept="image/png, image/jpeg"
                      id="image_upload"
                      disabled={order.status !== "draft"}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div className="relative w-full mb-3">
                  <img
                    src={require("assets/img/order-edit/pen-edit.svg").default}
                    alt={"pen-edit-icon"}
                    className="w-4 absolute top-4 right-4 svg_gray"
                  />
                  <textarea
                    name="notification_body"
                    rows={5}
                    className="border border-tertiary_gray_two focus:ring-0 border-dashed pl-3 pr-14 py-3 placeholder-slate-400 text-slate-600 bg-white rounded text-sm focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder={defaultBody[values.notification_lang]}
                    value={values.notification_body}
                    onChange={handleChange}
                    disabled={order.status !== "draft"}
                  ></textarea>
                </div>
              </div>

              <div className="w-full">
                <div className="relative w-full mb-3">
                  <img
                    src={require("assets/img/order-edit/pen-edit.svg").default}
                    alt={"pen-edit-icon"}
                    className="w-4 absolute top-4 right-4"
                  />
                  <input
                    name="notification_action"
                    type="text"
                    className="border bg-secondarySix font-bold text-center text-white border-tertiary_gray_two focus:ring-0 border-dashed pl-3 pr-14 py-3 placeholder-white rounded-lg focus:outline-none w-full ease-linear transition-all duration-150"
                    placeholder={defaultAction[values.notification_lang]}
                    value={values.notification_action}
                    onChange={handleChange}
                    disabled={order.status !== "draft"}
                  />
                </div>
              </div>
            </div>

            {/* notification option  */}
            <div className="w-7/12 mx-auto font-medium text-primaryTwo mt-7">
              <h6 className="text-2xl mb-5">Get the notification status of your gifts :</h6>

              <div className="flex gap-3">
                <div
                  className={`flex items-center gap-4 bg-white py-3 px-8 rounded-full border border-gray-300 select-none ${
                    notificationOption.email_notification ? "bg-tertiary_gray_one" : ""
                  } `}
                >
                  <div className="flex">
                    <img src={require("assets/img/gmail-logo.png").default} alt={"gmail"} className="w-6 mr-2" />
                    Email
                  </div>

                  <img
                    src={require("assets/img/order-edit/suggestion-check.svg").default}
                    alt={"check"}
                    className="w-4 svg_black"
                  />
                </div>

                <div
                  className={`flex items-center gap-4 bg-white py-3 px-8 rounded-full border border-gray-300 select-none ${
                    notificationOption.whatsapp_notification ? "bg-tertiary_gray_one" : ""
                  } `}
                >
                  <div className="flex">
                    <img src={require("assets/img/whatsapp-logo.png").default} alt={"whatsapp"} className="w-6 mr-2" />
                    WhatsApp
                  </div>
                  <img
                    src={require("assets/img/order-edit/suggestion-check.svg").default}
                    alt={"check"}
                    className="w-4 svg_black"
                  />
                </div>

                {!integration.active ? (
                  <Link
                    to="/integrations/slack"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`flex items-center gap-4 bg-white py-3 px-8 rounded-full border border-gray-300`}
                  >
                    <div className="flex">
                      <img src={require("assets/img/slack-logo.png").default} alt={"slack"} className="w-6 mr-2" />
                      Slack
                    </div>

                    <div className="border border-primaryTwo h-3 w-3 bg-white rounded-full"></div>
                  </Link>
                ) : (
                  <div
                    className={`flex items-center gap-4 bg-tertiary_gray_one py-3 px-8 rounded-full border border-gray-300 select-none`}
                  >
                    <div className="flex">
                      <img src={require("assets/img/slack-logo.png").default} alt={"slack"} className="w-6 mr-2" />
                      Slack
                    </div>

                    <img
                      src={require("assets/img/order-edit/suggestion-check.svg").default}
                      alt={"check"}
                      className="w-4 svg_black"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* progress button */}
          <div className="flex justify-center items-center gap-x-5 w-fit mx-auto mb-2 mt-8">
            {!order.activate && (
              <button
                onClick={() => {
                  setStep("recipient");
                }}
                className="py-3 px-10 w-fit bg-white text-primary font-bold mx-auto flex items-center rounded-lg border border-primary"
              >
                <img
                  src={require("assets/img/arrow-right.svg").default}
                  alt={"home-no-campaign-icon"}
                  className="w-6 mr-2 svg_orange rotate-180"
                />
                <p>Back</p>
              </button>
            )}

            <button
              className="py-3 px-10 w-fit bg-primary text-white font-bold mx-auto flex items-center rounded-lg"
              onClick={onSubmit}
            >
              {loading && <Spinner />} Wrapping
              <img
                src={require("assets/img/arrow-right.svg").default}
                alt={"home-no-campaign-icon"}
                className="w-6 ml-2"
              />
            </button>
          </div>
        </div>
      )}

      {/* Gift Wrapping  */}
      {wrapping && (
        <div>
          {previewWrapping && (
            <Modal
              hideHeader={true}
              title={``}
              widthClass="w-8/12"
              noPadding={true}
              onClose={() => setPreviewWrapping((prev) => !prev)}
              body={body}
            />
          )}

          <div className="bg-white p-9 rounded-lg border border-tertiary_gray_two mt-10">
            <div className="border-b border-tertiary_gray_two pb-6 mb-12">
              <h3 className="text-2xl font-bold text-center">Choose Wrapping (Optional)</h3>
            </div>

            {/* wrapGif  */}
            <div className="grid grid-cols-3 gap-5">
              {/* no wrap  */}
              <div
                className={`${
                  wrapValue.gift_wrapping === null ? "border-secondaryOne" : "border-tertiary_gray_two"
                } mb-2 mt-4 rounded-lg border-2 bg-white `}
              >
                {wrapValue.gift_wrapping === null && (
                  <img
                    src={require("assets/img/order-edit/check-blue.png").default}
                    alt={"check"}
                    className="w-10 h-10 ml-2 mt-2 absolute z-10"
                  />
                )}
                <div className="">
                  <div className="relative group">
                    <img
                      src={require("assets/img/order-edit/no-wrapping.png").default}
                      alt={"no-wrapping"}
                      className="w-full h-44 rounded-t-lg"
                    />
                    <div className="absolute h-44 top-0 bottom-0 right-0 left-0 bg-[#000000a1] rounded-t-lg hidden group-hover:flex flex-col justify-center items-center gap-2">
                      {wrapValue.gift_wrapping !== null && (
                        <p
                          onClick={() =>
                            setWrapValue({
                              ...wrapValue,
                              gift_wrapping: null,
                            })
                          }
                          className="bg-gray-50 flex gap-2 items-center justify-center px-6 py-2 rounded-full w-1/2 hover:bg-gray-200 font-medium cursor-pointer"
                        >
                          {patchLoading ? (
                            <Spinner />
                          ) : (
                            <img
                              src={require("assets/img/order-edit/check-gray.svg").default}
                              alt={"check"}
                              className="w-5 h-5"
                            />
                          )}
                          Select
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="p-3 rounded-b-lg">
                    <h3 className="font-bold mb-1">No Wrapping</h3>
                    <p className="text-sm">
                      If you choose this option, recipients will receive your gift without wrapping
                    </p>
                  </div>
                </div>
              </div>

              {/* scratch  */}
              <div
                className={`${
                  wrapValue.gift_wrapping === "scratch" ? "border-secondaryOne" : "border-tertiary_gray_two"
                } mb-2 mt-4 rounded-lg border-2 bg-white `}
              >
                {wrapValue.gift_wrapping === "scratch" && (
                  <img
                    src={require("assets/img/order-edit/check-blue.png").default}
                    alt={"check"}
                    className="w-10 h-10 ml-2 mt-2 absolute z-10"
                  />
                )}
                <div className="">
                  <div className="relative group">
                    <img
                      src={require("assets/img/order-edit/wrapping-scratch.png").default}
                      alt={"wrapping-scratch"}
                      className="w-full h-44 rounded-t-lg"
                    />
                    <div className="absolute h-44 top-0 bottom-0 right-0 left-0 bg-[#000000a1] rounded-t-lg hidden group-hover:flex flex-col justify-center items-center gap-2">
                      {wrapValue.gift_wrapping !== "scratch" && (
                        <p
                          onClick={() =>
                            setWrapValue({
                              ...wrapValue,
                              gift_wrapping: "scratch",
                            })
                          }
                          className="bg-gray-50 flex gap-2 items-center justify-center px-6 py-2 rounded-full w-1/2 hover:bg-gray-200 font-medium cursor-pointer"
                        >
                          {patchLoading ? (
                            <Spinner />
                          ) : (
                            <img
                              src={require("assets/img/order-edit/check-gray.svg").default}
                              alt={"check"}
                              className="w-5 h-5"
                            />
                          )}
                          Select
                        </p>
                      )}
                      <p
                        onClick={() => {
                          setPreviewWrapping((prev) => !prev);
                          setWrapGif("scratch");
                        }}
                        className="bg-gray-50 flex gap-2 items-center justify-center px-6 py-2 rounded-full w-1/2 hover:bg-gray-200 font-medium cursor-pointer"
                      >
                        <img
                          src={require("assets/img/order-edit/eyes-open.svg").default}
                          alt={"eyes-open"}
                          className="w-5 h-5"
                        />
                        Preview
                      </p>
                    </div>
                  </div>

                  <div className="p-3 rounded-b-lg">
                    <h3 className="font-bold mb-1">Scratch Me</h3>
                    <p className="text-sm">Scratch to reveal experience.</p>
                  </div>
                </div>
              </div>

              {/* openbox  */}
              <div
                className={`${
                  wrapValue.gift_wrapping === "openbox" ? "border-secondaryOne" : "border-tertiary_gray_two"
                } mb-2 mt-4 rounded-lg border-2 bg-white `}
              >
                {wrapValue.gift_wrapping === "openbox" && (
                  <img
                    src={require("assets/img/order-edit/check-blue.png").default}
                    alt={"check"}
                    className="w-10 h-10 ml-2 mt-2 absolute z-10"
                  />
                )}
                <div className="">
                  <div className="relative group">
                    <img
                      src={require("assets/img/order-edit/wrapping-openbox.png").default}
                      alt={"wrapping-openbox"}
                      className="w-full h-44 rounded-t-lg"
                    />
                    <div className="absolute h-44 top-0 bottom-0 right-0 left-0 bg-[#000000a1] rounded-t-lg hidden group-hover:flex flex-col justify-center items-center gap-2">
                      {wrapValue.gift_wrapping !== "openbox" && (
                        <p
                          onClick={() =>
                            setWrapValue({
                              ...wrapValue,
                              gift_wrapping: "openbox",
                            })
                          }
                          className="bg-gray-50 flex gap-2 items-center justify-center px-6 py-2 rounded-full w-1/2 hover:bg-gray-200 font-medium cursor-pointer"
                        >
                          {patchLoading ? (
                            <Spinner />
                          ) : (
                            <img
                              src={require("assets/img/order-edit/check-gray.svg").default}
                              alt={"check"}
                              className="w-5 h-5"
                            />
                          )}
                          Select
                        </p>
                      )}
                      <p
                        onClick={() => {
                          setPreviewWrapping((prev) => !prev);
                          setWrapGif("openbox");
                        }}
                        className="bg-gray-50 flex gap-2 items-center justify-center px-6 py-2 rounded-full w-1/2 hover:bg-gray-200 font-medium cursor-pointer"
                      >
                        <img
                          src={require("assets/img/order-edit/eyes-open.svg").default}
                          alt={"eyes-open"}
                          className="w-5 h-5"
                        />
                        Preview
                      </p>
                    </div>
                  </div>

                  <div className="p-3 rounded-b-lg">
                    <h3 className="font-bold mb-1">Snap In The Box</h3>
                    <p className="text-sm">What&lsquo;s in the box?! A fun and cute surprise from a special guest</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* progress button */}
          <div className="flex justify-center items-center gap-x-5 w-fit mx-auto mb-2 mt-8">
            {!order.activate && (
              <button
                onClick={() => setWrapping(false)}
                className="py-3 px-10 w-fit bg-white text-primary font-bold mx-auto flex items-center rounded-lg border border-primary"
              >
                <img
                  src={require("assets/img/arrow-right.svg").default}
                  alt={"home-no-campaign-icon"}
                  className="w-6 mr-2 svg_orange rotate-180"
                />
                <p>Back</p>
              </button>
            )}

            {!order.activate && (
              <button
                className="py-3 px-10 w-fit bg-primary text-white font-bold mx-auto flex items-center rounded-lg"
                onClick={onWrapUpdate}
              >
                {patchLoading && <Spinner />} Preview
                <img
                  src={require("assets/img/arrow-right.svg").default}
                  alt={"home-no-campaign-icon"}
                  className="w-6 ml-2"
                />
              </button>
            )}

            {order.activate && (
              <button
                className="flex mb-2 py-4 px-8 font-bold text-white border w-fit bg-primary border-primary rounded-md cursor-pointer"
                onClick={() => setShowPublishModal(true)}
              >
                <img src={require("assets/img/order-edit/publish.png").default} alt={"publish"} className="w-6 mr-2" />
                <p>Publish</p>
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderMessage;
