import Spinner from "components/Spinner";
import { useError } from "contexts/error";
import api from "lib/api";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function IntegrationSlack() {
  const redirectUri = `${window.location.protocol}//${window.location.host}/integrations/slack`;
  const clientId = "2952322304482.3314541653763";
  const href = encodeURI(
    `https://slack.com/oauth/v2/authorize?scope=users:read,users:read.email,im:write,chat:write&user_scope=&redirect_uri=${redirectUri}&client_id=${clientId}`
  );

  const [integration, setIntegration] = useState({ active: false });
  const { search } = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingIntegration, setLoadingIntegration] = useState(false);
  const { handleError } = useError();

  const submit = async (code) => {
    setLoading(true);
    const { err, data } = await api.controllers.integrationAuthSlack(code, redirectUri);
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      setIntegration(data.data);
    }
  };

  const get = async () => {
    setLoadingIntegration(true);
    const { err, data } = await api.controllers.getIntegration("slack");
    setLoadingIntegration(false);
    if (!err) {
      setIntegration(data.data);
    }
  };

  useEffect(() => {
    get();
    const params = new URLSearchParams(search);
    const code = params.get("code");
    if (code) {
      submit(code);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full mb-6 shadow-lg rounded-lg border-0 p-6 bg-white">
      <div className="mb-12">
        <img
          src={require(`assets/img/slack.png`).default}
          alt={"slack-logo"}
          className={`w-28 h-28 mx-auto object-contain m-6`}
        />
        {loadingIntegration ? (
          <div className="w-fit mx-auto">
            <Spinner color={"black"} />
          </div>
        ) : (
          <p
            className={`text-xs font-medium py-1 px-2 w-fit mx-auto rounded-full ${
              integration.active ? "text-green-600 bg-green-100" : "text-red-600 bg-red-100"
            }`}
          >
            <i className={`fas ${integration.active ? "fa-check-circle" : "fa-times-circle"} mr-1`} />{" "}
            {integration.active ? "Integration Enabled" : "Not Active"}
          </p>
        )}
      </div>

      {!loadingIntegration && (
        <div className="px-5 py-5 bg-[#F0F5FA]">
          <h6 className="text-slate-700 text-xl font-bold mb-4">Slack Integration</h6>
          <p className="text-base mb-2">
            With slack integration, Yippy will be able to send message to your employees for:
          </p>
          <ul className="pl-4 mb-4">
            <li className="list-disc">Asking for updated delivery address</li>
            <li className="list-disc">Notifying delivery status</li>
          </ul>

          {!integration.active && (
            <div>
              <a
                href={href}
                style={{
                  alignItems: "center",
                  color: "#fff",
                  backgroundColor: "#012F48",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  display: "inline-flex",
                  fontFamily: "Lato, sans-serif",
                  fontSize: "16px",
                  fontWeight: "600",
                  height: "48px",
                  justifyContent: "center",
                  textDecoration: "none",
                  width: "236px",
                }}
              >
                {loading && <Spinner />}
                {!loading && (
                  <p>
                    <img
                      src={require(`assets/img/slack-white.svg`).default}
                      alt={"slack-logo"}
                      className={`w-5 h-5 mr-3 object-contain`}
                    />
                    Add to Slack
                  </p>
                )}
              </a>

              {loading && (
                <p className="text-xs text-gray-400">Please wait for a few seconds while we are syncing the data...</p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
