import Spinner from "components/Spinner";
import React from "react";

function FormDelivery({ values, handleChange, setFormBody, loading }) {
  return (
    <div className="w-10/12 mx-auto mb-4">
      <img
        src={require("assets/img/new-order/new-order-recipients-icon.png").default}
        alt={"new-order-recipients-icon"}
        className="w-32 mx-auto py-4"
      />

      <label className="block font-bold text-center text-3xl">How do we send the gifts?</label>

      {/* if type == personal */}
      {values.type === "branded" && (
        <div>
          <div className="mt-5 px-4 mb-2 grid grid-cols-2 gap-x-5">
            <div
              className={`mb-2 mt-4 rounded-lg bg-neutral-100 ${
                values.deliver_to === "company" ? "border border-indigo-300" : ""
              }`}
            >
              <div className="flex items-center">
                <input
                  id="to-company"
                  type="radio"
                  name="deliver_to"
                  onChange={handleChange}
                  value="company"
                  checked={values.deliver_to === "company"}
                  className="h-4 w-4 mr-2 border-gray-300 focus:ring-2 focus:ring-blue-300 opacity-0"
                />
                <label htmlFor="to-company" className="block text-center -ml-5 cursor-pointer pb-3 px-3">
                  <img
                    src={require("assets/img/new-order/new-order-deliery-company.png").default}
                    alt={"new-order-deliery-company"}
                    className="w-12 mx-auto pt-4"
                  />
                  <h3 className="font-bold mb-2">Single Address</h3>
                  <p className="text-sm">
                    We will send all gifts to single address of your choice: office, warehouse or event venue. You will
                    handle the logistics to recipients.
                  </p>
                </label>
              </div>
            </div>

            <div
              className={`mb-2 mt-4 rounded-lg bg-neutral-100 ${
                values.deliver_to === "recipients" ? "border border-indigo-300" : ""
              }`}
            >
              <div className="flex items-center ">
                <input
                  id="to-recipients"
                  type="radio"
                  name="deliver_to"
                  onChange={handleChange}
                  value="recipients"
                  checked={values.deliver_to === "recipients"}
                  className="h-4 w-4 mr-2 border-gray-300 focus:ring-2 focus:ring-blue-300 opacity-0"
                />
                <label htmlFor="to-recipients" className="block text-center -ml-5 cursor-pointer pb-3 px-3">
                  <img
                    src={require("assets/img/new-order/new-order-deliery-recipients.png").default}
                    alt={"new-order-deliery-recipients"}
                    className="w-12 mx-auto pt-4"
                  />
                  <h3 className="font-bold mb-2">To Recipients</h3>
                  <p className="text-sm">
                    We will help you collect recipients addresses, sending it to them and delivery tracking. All we need
                    is their email addresses.
                  </p>
                </label>
              </div>
            </div>
          </div>

          {/* date  */}
          {values.deliver_to.length > 0 && (
            <div
              className="w-[95%] mx-auto grid grid-cols-2 gap-x-5 pt-4 px-4 border border-blue-300
            rounded-lg"
            >
              {values.deliver_to === "company" && (
                <div className="w-full mb-3">
                  <label className="block font-bold">Quantity </label>
                  <input
                    name="recipient_count"
                    type="number"
                    min={0}
                    className="border-0 px-3 py-3 mb-2 placeholder-slate-300 text-slate-600 bg-white border-b-2 border-primary focus:border-primary rounded w-full focus:ring-0"
                    placeholder="Quantity"
                    value={values.recipient_count}
                    onChange={handleChange}
                  />
                </div>
              )}

              {values.deliver_to === "recipients" && (
                <div className="w-full mb-3">
                  <label className="block font-bold">No. Recipients </label>
                  <input
                    name="recipient_count"
                    type="number"
                    min={0}
                    className="border-0 px-3 py-3 mb-2 placeholder-slate-300 text-slate-600 bg-white border-b-2 border-primary focus:border-primary rounded w-full focus:ring-0"
                    placeholder="recipients"
                    value={values.recipient_count}
                    onChange={handleChange}
                  />
                </div>
              )}

              <div className="w-full mb-3">
                <label className="block font-bold">Expected Delivery Date</label>
                <input
                  name="delivery_date"
                  type="date"
                  className="border-0 px-3 py-3 mb-2 placeholder-slate-300 text-slate-600 bg-white border-b-2 border-primary focus:border-primary rounded w-full focus:ring-0"
                  placeholder="Date"
                  value={values.delivery_date}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {/* if type == branded */}
      {values.type === "personal" && (
        <div className="w-[95%] mx-auto grid grid-cols-2 gap-x-5 mt-20 px-4 mb-2">
          <div className="w-full mb-3">
            <label className="block font-bold">No. Recipients </label>
            <input
              name="recipient_count"
              type="number"
              min={0}
              className="border-0 px-3 py-3 my-2 placeholder-slate-300 text-slate-600 bg-white border-b-2 border-primary focus:border-primary rounded w-full focus:ring-0"
              placeholder="Recipients"
              value={values.recipient_count}
              onChange={handleChange}
            />
          </div>

          <div className="w-full mb-3">
            <label className="block font-bold">Expected Delivery Date </label>
            <input
              name="delivery_date"
              type="date"
              className="border-0 px-3 py-3 my-2 placeholder-slate-300 text-slate-600 bg-white border-b-2 border-primary focus:border-primary rounded w-full focus:ring-0"
              placeholder="Date"
              value={values.delivery_date}
              onChange={handleChange}
            />
          </div>
        </div>
      )}

      <div className="flex justify-center items-center gap-x-5 w-fit mx-auto mb-2 mt-8">
        <div
          className="py-3 px-10 w-fit bg-white text-primary font-bold cursor-pointer mx-auto flex items-center rounded-lg border border-primary"
          onClick={() => setFormBody("type")}
        >
          <img
            src={require("assets/img/arrow-right.svg").default}
            alt={"home-no-campaign-icon"}
            className="w-4 h-4 mr-2 svg_orange rotate-180"
          />
          <p>back</p>
        </div>

        <button
          className="py-3 px-10 w-fit bg-primary text-white font-bold cursor-pointer mx-auto flex items-center rounded-lg"
          type="submit"
        >
          {loading && <Spinner size={4} />} Create
        </button>
      </div>
    </div>
  );
}

export default FormDelivery;
