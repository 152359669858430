import Grid from "components/Grid/Grid";
import api from "lib/api";
import { useCallback, useEffect, useState } from "react";
import OrderRecipientProducts from "./OrderRecipientProducts";
import OrderRecipientsTrackModal from "./OrderRecipientsTrackModal";

export default function OrderRecipients({ order }) {
  const [data, setData] = useState({});
  const [pagination, setPagination] = useState(null);
  const [recipientTrack, setRecipientTrack] = useState(null);
  const [recipientGift, setRecipientGift] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterValues, setFilterValues] = useState(null);

  const fetchOrderRecipients = useCallback(async () => {
    setLoading(true);
    const { data, err } = await api.controllers.getOrderRecipients(order.id, {
      page: pagination && pagination.page,
      limit: pagination && pagination.limit,
      ...filterValues,
    });
    setLoading(false);
    if (!err) {
      setData(data);
    }
  }, [filterValues, pagination, order.id]);

  // let get = async () => {
  //   setLoading(true);
  //   const { data, err } = await api.controllers.getOrderRecipients(order.id, {
  //     page: pagination && pagination.page,
  //     limit: pagination && pagination.limit,
  //     ...filterValues,
  //   });
  //   setLoading(false);
  //   if (!err) {
  //     setData(data);
  //   }
  // };

  useEffect(() => {
    pagination && fetchOrderRecipients();
  }, [pagination, fetchOrderRecipients]);

  // useEffect(() => {
  //   filterValues && get();
  // }, [filterValues]);

  const onFilter = async (_values) => {
    setFilterValues(_values);
  };

  let render = (recipient) => {
    return (
      <div className="bg-white py-2 px-4 rounded-lg shadow-lg h-full flex flex-col justify-between">
        <div>
          {" "}
          <div className="flex justify-between">
            <h3 className="mr-2 font-bold">{recipient.recipient_name}</h3>
            {recipient.status === "address_requested" && (
              <p className="text-right bg-gray-200 text-gray-500 p-1 text-xs font-bold rounded-md">Unclaimed</p>
            )}
            {recipient.status === "processing" && (
              <p className="text-right bg-yellow-100 text-yellow-500 p-1 text-xs font-bold rounded-md">Processing</p>
            )}
            {recipient.status === "delivering" && (
              <p className="text-right bg-yellow-100 text-yellow-500 p-1 text-xs font-bold rounded-md">Delivering</p>
            )}
            {recipient.status === "delivered" && (
              <p className="text-right bg-green-100 text-green-500 p-1 text-xs font-bold rounded-md">Delivered</p>
            )}
          </div>
          <p>{recipient.recipient_phone_number}</p>
          <p>{recipient.recipient_email}</p>
        </div>
        <div className="flex my-2 gap-x-2">
          {recipient.products.length > 0 && (
            <button
              className="bg-yellow-500 text-white font-bold rounded-lg py-2 px-4 mr-2"
              onClick={() => setRecipientGift(recipient)}
            >
              View Gift
            </button>
          )}

          {(recipient.status === "delivering" || recipient.status === "delivered") && (
            <button
              className="bg-primaryTwo text-white text-sm font-bold rounded-lg py-2 px-4"
              onClick={() => setRecipientTrack(recipient)}
            >
              Track
            </button>
          )}

          {recipient.status !== "delivering" && recipient.status !== "delivered" && (
            <button className="bg-gray-400 text-white text-sm font-bold rounded-lg py-2 px-4" disabled>
              Track
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Grid
        settings={{
          limit: 12,
          cols: 3,
          filters: [
            {
              field: "recipient_name",
              label: "Name",
            },
            {
              field: "recipient_email",
              label: "Email",
            },
            {
              label: "Status",
              field: "status",
              inputType: "select",
              options: [
                {
                  label: "Unclaimed",
                  value: "address_requested",
                },
                {
                  label: "Processing",
                  value: "processing",
                },
                {
                  label: "Delivering",
                  value: "delivering",
                },
                {
                  label: "Delivered",
                  value: "delivered",
                },
              ],
            },
          ],
        }}
        render={render}
        data={data}
        setPagination={setPagination}
        loading={loading}
        onFilter={onFilter}
      />

      {recipientTrack && (
        <OrderRecipientsTrackModal onClose={() => setRecipientTrack(null)} order={order} recipient={recipientTrack} />
      )}
      {recipientGift && (
        <OrderRecipientProducts onClose={() => setRecipientGift(null)} order={order} recipient={recipientGift} />
      )}
    </div>
  );
}
