import React, { useCallback, useEffect, useState } from "react";
// import {  useHistory } from "react-router-dom";

import Table from "components/Table/Table.js";
import api from "lib/api";
import Spinner from "components/Spinner";
import Modal from "components/Modal/Modal";

import AsyncSelect from "react-select/async";

import { useError } from "contexts/error";
import { useParams } from "react-router-dom";

export default function GroupRecipientList() {
  const [data, setData] = useState({});
  const [pagination, setPagination] = useState(null);
  // const history = useHistory();
  const { handleError } = useError();
  const { id } = useParams();

  const [values, setValues] = useState({ recipients: [] });
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterValues, setFilterValues] = useState(null);

  const fetchClients = useCallback(async () => {
    const { data, err } = await api.controllers.getGroupRecipients(id, pagination.page, pagination.limit, filterValues);
    if (!err) {
      setData(data);
    }
  }, [filterValues, pagination, id]);

  const get = async () => {
    const { data, err } = await api.controllers.getGroupRecipients(id, pagination.page, pagination.limit, filterValues);
    if (!err) {
      setData(data);
    }
  };

  useEffect(() => {
    pagination && fetchClients();
  }, [pagination, fetchClients]);

  // useEffect(() => {
  //   filterValues && get();
  // }, [filterValues]);

  const onFilter = async (_values) => {
    setFilterValues(_values);
  };

  let onSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const { err } = await api.controllers.addGroupRecipients(id, {
      recipients: values.recipients,
    });
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      setShowModal(false);
      get();
    }
  };

  let loadOptions = async (inputValue, callback) => {
    const { err, data } = await api.controllers.getRecipients(1, -1, {
      email: inputValue,
    });
    if (!err) {
      let options = data.data.map((recipient) => {
        return {
          label: `${recipient.name} (${recipient.email})`,
          value: recipient.id,
        };
      });
      callback(options);
    } else {
      callback([]);
    }
  };

  let onValuesChange = (_values) => {
    setValues({
      recipients: _values.map((value) => {
        return value.value;
      }),
    });
  };

  const form = (
    <form onSubmit={onSubmit}>
      <AsyncSelect
        placeholder={"Search email"}
        isMulti={true}
        loadOptions={loadOptions}
        onChange={(values) => onValuesChange(values)}
      />

      <button
        className="mt-4 flex bg-primary text-white font-bold uppercase text-xs px-4 py-2 rounded shadow mr-1"
        type="submit"
      >
        {loading && <Spinner size={4} />} Add
      </button>
    </form>
  );

  const modal = <Modal title={"Add Recipients"} onClose={() => setShowModal(false)} body={form} />;

  let onRecipientDelete = async (recipient) => {
    const { err } = await api.controllers.deleteGroupRecipient(id, recipient.id);

    if (err) {
      handleError(err);
    } else {
      get();
    }
  };

  return (
    <div>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <Table
            settings={{
              title: "Recipients",
              button: {
                label: "Add",
                onClick: () => {
                  setShowModal(true);
                },
              },
              headers: [
                {
                  label: "Name",
                  field: "name",
                },
                {
                  label: "Email",
                  field: "email",
                },
                {
                  label: "Phone Number",
                  field: "phone_number",
                },
                {
                  label: "",
                  render: (element) => {
                    return (
                      <button type="button" className="" onClick={(e) => onRecipientDelete(element)}>
                        <i className="fas fa-trash"></i>
                      </button>
                    );
                  },
                },
              ],
              filters: [
                {
                  label: "Name",
                  field: "name",
                },
                {
                  label: "Email",
                  field: "email",
                  inputType: "email",
                },
                {
                  label: "Phone Number",
                  field: "phone_number",
                  inputType: "tel",
                },
              ],
            }}
            data={data}
            setPagination={setPagination}
            onFilter={onFilter}
          />
        </div>
      </div>

      {showModal ? modal : null}
    </div>
  );
}
