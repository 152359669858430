import React, { useEffect, useState } from "react";
import Spinner from "components/Spinner";
import api from "lib/api";
import { Link } from "react-router-dom";
import { useError } from "contexts/error";

let conditionOptions = {
  onboarding: {
    label: "Onboarding",
    field: "onboarding",
    type: "select",
    readonly: true,
    operators: [
      {
        label: "In",
        value: "in",
      },
    ],
    values: [
      {
        label: "1 week",
        value: "1_week",
      },
      {
        label: "2 week",
        value: "2_week",
      },
      {
        label: "1 month",
        value: "1_month",
      },
    ],
  },
  birthday: {
    label: "Birthday",
    field: "birthday",
    type: "select",
    readonly: true,
    operators: [
      {
        label: "In",
        value: "in",
      },
    ],
    values: [
      {
        label: "1 week",
        value: "1_week",
      },
      {
        label: "2 week",
        value: "2_week",
      },
      {
        label: "1 month",
        value: "1_month",
      },
    ],
  },
  anniversary: {
    label: "Anniversary",
    field: "anniversary",
    type: "select",
    readonly: true,
    operators: [
      {
        label: "In",
        value: "in",
      },
    ],
    values: [
      {
        label: "1 week",
        value: "1_week",
      },
      {
        label: "2 week",
        value: "2_week",
      },
      {
        label: "1 month",
        value: "1_month",
      },
    ],
  },
  tenure: {
    label: "Work Tenure",
    field: "tenure",
    type: "select",
    readonly: false,
    operators: [
      {
        label: ">=",
        value: ">=",
      },
      {
        label: "<=",
        value: "<=",
      },
    ],
    values: [
      {
        label: "3 months",
        value: 3,
      },
      {
        label: "6 months",
        value: 6,
      },
      {
        label: "1 year",
        value: 12,
      },
      {
        label: "2 years",
        value: 24,
      },
      {
        label: "3 years",
        value: 36,
      },
      {
        label: "4 years",
        value: 48,
      },
      {
        label: "5 years",
        value: 60,
      },
    ],
  },
  group: {
    label: "Group",
    field: "group",
    type: "select",
    readonly: false,
    operators: [
      {
        label: "=",
        value: "=",
      },
    ],
    values: [],
  },
};

const OrderRecurringRecipients = ({ order, setStep }) => {
  // const [groups, setGroups] = useState([]);

  const [values, setValues] = useState({
    recurring_occasion: order.recurring_occasion || "onboarding",
    conditions: [],
  });
  const [loading, setLoading] = useState(false);
  const { handleError } = useError();

  let getOrderConditions = async () => {
    let { err, data } = await api.controllers.getOrderConditions(order.id);
    if (!err) {
      let orderConditions = data.data;
      if (orderConditions.length > 0) {
        setValues({ ...values, conditions: orderConditions });
      }
    }
  };

  let getGroups = async () => {
    const { data, err } = await api.controllers.getGroups(1, -1);
    if (!err) {
      let _groups = data.data;
      // setGroups(_groups);
      conditionOptions.group.values = _groups.map((group) => {
        return {
          label: group.name,
          value: group.id,
        };
      });
    }
  };

  useEffect(() => {
    getGroups();
    getOrderConditions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let conditions = [
      {
        field: values.recurring_occasion,
        operator: conditionOptions[values.recurring_occasion].operators[0].value,
        value: conditionOptions[values.recurring_occasion].values[0].value,
      },
    ];

    setValues((values) => ({ ...values, conditions: conditions }));
  }, [values.recurring_occasion]);

  let onOccasionChange = (occasion) => {
    setValues({ ...values, recurring_occasion: occasion });
  };

  let onFieldChange = (index, field) => {
    let newConditions = [...values.conditions];
    newConditions[index].field = field;
    newConditions[index].operator = conditionOptions[field].operators[0].value;
    newConditions[index].value = conditionOptions[field].values[0].value;

    setValues({ ...values, conditions: newConditions });
  };

  let onOperatorChange = (index, operator) => {
    let newConditions = [...values.conditions];
    newConditions[index].operator = operator;

    setValues({ ...values, conditions: newConditions });
  };

  let onValueChange = (index, value) => {
    let newConditions = [...values.conditions];
    newConditions[index].value = value;

    setValues({ ...values, conditions: newConditions });
  };

  let onConditionAdd = () => {
    let newConditions = [...values.conditions];
    newConditions.push({
      field: "tenure",
      operator: conditionOptions["tenure"].operators[0].value,
      value: conditionOptions["tenure"].values[0].value,
    });
    setValues({ ...values, conditions: newConditions });
  };

  let onConditionDelete = (index) => {
    let newConditions = values.conditions.filter((condition, _index) => _index !== index);
    setValues({ ...values, conditions: newConditions });
  };

  let onSubmit = async (e) => {
    setLoading(true);
    const { err } = await api.controllers.addOrderConditions(order.id, values);
    setLoading(false);

    if (err) {
      handleError(err);
    } else {
      if (order.type === "branded") {
        setStep("design");
      } else if (order.type === "personal") {
        setStep("message");
      }
    }
  };

  let renderCondition = (condition, index) => {
    let field = condition.field;
    let operator = condition.operator;
    let value = condition.value;
    let option = conditionOptions[field];

    return (
      <div className="flex gap-x-4 mb-4" key={`condition-${index}`}>
        <div className="relative w-40">
          <select
            className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow w-full"
            value={field}
            onChange={(e) => onFieldChange(index, e.target.value)}
          >
            {option.readonly && <option value={field}>{option.label}</option>}

            {!option.readonly && (
              <p>
                {Object.entries(conditionOptions)
                  .filter((condition) => condition[1].readonly === false)
                  .map(([key, value]) => (
                    <option key={`field-${value.field}`} value={value.field}>
                      {value.label}
                    </option>
                  ))}
              </p>
            )}
          </select>
        </div>
        <div className="relative w-40">
          <select
            className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow w-full"
            value={operator}
            onChange={(e) => onOperatorChange(index, e.target.value)}
          >
            {conditionOptions[field].operators.map((operator) => (
              <option value={operator.value} key={`operator-${operator.value}`}>
                {operator.label}
              </option>
            ))}
          </select>
        </div>
        <div className="relative w-40">
          <select
            className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow w-full"
            value={value}
            onChange={(e) => onValueChange(index, e.target.value)}
          >
            {conditionOptions[field].values.map((value) => (
              <option value={value.value} key={`value-${value.value}`}>
                {value.label}
              </option>
            ))}
          </select>
        </div>
        {!option.readonly && (
          <button onClick={() => onConditionDelete(index)}>
            <i className="fas fa-trash"></i>
          </button>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="w-full mt-10">
        <div className="text-left">
          <span className="p-2 border border-gray-200 rounded-lg mb-4 inline-block">
            Make sure that you have uploaded your recipients{" "}
            <Link to="/recipients" target={"_blank"} className="underline">
              here
            </Link>{" "}
            before you can use this feature.
          </span>
        </div>
        <h2 className="text-lg font-bold mb-4">Choose the Occasion</h2>

        <div className="flex justify-start w-full gap-x-10 mb-4">
          <div
            className={`bg-gray-100 py-4 px-10 text-center rounded-lg cursor-pointer ${
              values.recurring_occasion === "onboarding" ? "border border-primary" : ""
            }`}
            onClick={() => onOccasionChange("onboarding")}
          >
            <img
              src={require("assets/img/onboarding.png").default}
              alt={"Onboarding"}
              className={"w-20 h-20 block mb-2"}
            />
            <h3 className="font-bold">Onboarding</h3>
          </div>

          <div
            className={`bg-gray-100 py-4 px-10 text-center rounded-lg cursor-pointer ${
              values.recurring_occasion === "birthday" ? "border border-primary" : ""
            }`}
            onClick={() => onOccasionChange("birthday")}
          >
            <img src={require("assets/img/birthday.png").default} alt={"Birthday"} className={"w-20 h-20 block mb-2"} />
            <h3 className="font-bold">Birthday</h3>
          </div>

          <div
            className={`bg-gray-100 py-4 px-10 text-center rounded-lg cursor-pointer ${
              values.recurring_occasion === "anniversary" ? "border border-primary" : ""
            }`}
            onClick={() => onOccasionChange("anniversary")}
          >
            <img
              src={require("assets/img/anniversary.png").default}
              alt={"Anniversary"}
              className={"w-20 h-20 block mb-2"}
            />
            <h3 className="font-bold">Anniversary</h3>
          </div>
        </div>

        <h2 className="text-lg font-bold mb-4">Conditions</h2>
        {values.conditions.map((condition, index) => (
          <div key={`condition ${index}`}>{renderCondition(condition, index)}</div>
        ))}

        <button
          className="flex bg-primaryTwo text-white font-bold uppercase text-xs px-10 py-2 rounded shadow mt-4"
          onClick={onConditionAdd}
        >
          Add Condition
        </button>
      </div>

      <div className="flex justify-end mt-10 mb-4">
        <button
          className="flex bg-primary text-white font-bold uppercase text-xs px-10 py-2 rounded shadow"
          onClick={onSubmit}
        >
          {loading && <Spinner />} {order.type === "branded" ? "Design" : "Message"}
        </button>
      </div>
    </div>
  );
};

export default OrderRecurringRecipients;
